import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { BusinessIdService } from '../../business/business-id.service';
import { ReferralProgramModalComponent } from '../../shared/components/referral-program/referral-program-modal/referral-program-modal.component';
import { FeatureFlags } from '../models/feature-flags.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { LmsSerials } from '../models/lms-serials.enum';
import { UserType } from '../models/user-type.enum';
import { coreSelectors } from '../store/core.selectors';
import { DynamicDialogService } from './dynamic-dialog.service';
import { FeatureFlagsService } from './feature-flags.service';
import { NavigationService } from './navigation.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramService {
  private store = inject(Store);
  private navigationService = inject(NavigationService);
  private trackingService = inject(TrackingService);
  private dynamicDialogService = inject(DynamicDialogService);
  private businessIdService = inject(BusinessIdService);

  referralProgramFF = inject(FeatureFlagsService).isActive(FeatureFlags.ReferralProgram);

  isReferralProgramVisible(): Observable<boolean> {
    return this.store.pipe(
      select(coreSelectors.getUserType),
      first(),
      map((userType: UserType) => {
        return this.referralProgramFF && userType === UserType.Customer;
      }),
    );
  }

  onClickReferralProgram(): void {
    const businessId = this.businessIdService.getBusinessId();
    this.navigationService.navigateByLMS(LmsSerials.PublicSiteReferralProgram, true, { businessid: businessId });
  }

  /* istanbul ignore next */
  trackReferralProgramClick(placement: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement,
        name: 'referral-program-click',
        eventName: `${placement} - CLICK referral program`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackReferralProgramModalSkipButtonClick(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'referral-program-click-modal',
        name: 'skip-button',
      },
      true,
    );
  }

  openReferralProgramModal(): void {
    this.dynamicDialogService.open(ReferralProgramModalComponent, {
      showHeader: false,
      dismissableMask: false,
    });
  }
}
