import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { catchErrorAndLog } from '../../../../../shared/utils/catch-error-and-log.utils';
import { ChatResponseStatuses } from '../../../../enums/chat-response-statuses.enum';
import { ChatSessionService } from '../../../../services/chat-session.service';
import { ChatSessionType } from '../../enums/chat-session-type.enum';
import { LOB } from '../../enums/lob.enum';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { FileUploadRequest } from '../../models/file-upload-request.model';
import { ResourceType } from '../../models/resource-type.model';
import { ResourceTypesResponse } from '../../models/resource-types-response.model';
import { FilesUploadDataService } from '../../services/file-upload.data.service';
import { FilesUploadService } from '../../services/files-upload.service';
import { RichMessagesService } from '../../services/rich-messages.service';

@Component({
  selector: 'ni-file-upload-rich-message',
  templateUrl: './file-upload-rich-message.component.html',
  styleUrls: ['./file-upload-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilesUploadService],
})
export class FileUploadRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.FileUpload;
  ButtonType = ButtonType;
  resourceTypes$: Observable<ResourceType[]>;
  fileUploadForm: UntypedFormGroup;
  fileToUpload: File;
  chatSessionId: string;
  isProcessing = false;
  isUploadingFinished = false;
  chatResponseStatuses = ChatResponseStatuses;
  lob: LOB;
  shouldDisplayAmount = false;
  private readonly defaultFileTypes: ResourceType[] = [
    {
      name: 'Photo',
      displayName: 'Photo',
    },
    {
      name: 'Receipt',
      displayName: 'Receipt',
    },
    {
      name: 'Other',
      displayName: 'Other',
    },
  ];

  private readonly requiredAmountResourcesTypes = ['Invoice', 'Receipt'];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private chatSessionService: ChatSessionService,
    private filesUploadService: FilesUploadService,
    private filesUploadDataService: FilesUploadDataService,
    private richMessagesService: RichMessagesService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initResourceTypes();
    this.initForm();
  }

  onSelectFileToUploadChanged(files: File[]): void {
    if (!files.length) {
      return;
    }
    this.fileToUpload = files[0];
    this.fileUploadForm.controls.selectedFile.patchValue(this.fileToUpload.name);
    this.changeDetector.detectChanges();
    this.fileUploadForm.controls.selectedFile.updateValueAndValidity();
  }

  finishUpload(status: ChatResponseStatuses): void {
    this.isProcessing = false;
    this.isUploadingFinished = true;
    this.changeDetector.detectChanges();
    this.richMessagesService.sendMessage({ actualMessage: status });
  }

  async submit(): Promise<void> {
    if (this.fileUploadForm.valid) {
      this.isProcessing = true;
      const fileUploadRequest: FileUploadRequest = await this.getClaimResourceRequest();
      this.filesUploadDataService
        .uploadChatbotClaimResources(ChatSessionType.Claims, fileUploadRequest)
        .pipe(
          catchErrorAndLog((err: HttpErrorResponse) => {
            if (err.error?.error?.extra?.niStatusCode === 'CLAIM_RESOURCE_ALREADY_EXISTS') {
              this.resetLoadingIndicators();
              this.fileUploadForm.controls.selectedFile.setErrors({
                fileAlreadyExist: this.filesUploadService.getFileAlreadyExistErrorMessage(),
              });
              this.changeDetector.detectChanges();
              return EMPTY;
            }
            this.finishUpload(this.chatResponseStatuses.Error);
            return throwError(err);
          }),
        )
        .subscribe(() => {
          this.finishUpload(this.chatResponseStatuses.Success);
        });
    } else {
      this.updateFormValuesValidity();
    }
  }

  private initData(): void {
    this.chatSessionId = this.chatSessionService.chatParameters ? this.chatSessionService.chatParameters?.sessionId : '';
    const lobType: LOB = this.chatSessionService.chatParameters ? (this.chatSessionService.chatParameters?.lob as LOB) : undefined;
    this.lob = lobType || LOB.GL;
  }

  private initResourceTypes(): void {
    this.resourceTypes$ = this.filesUploadDataService.getResourceTypes(this.lob).pipe(
      catchErrorAndLog(() => {
        return this.defaultFileTypes;
      }),
      map((resourceTypesResponse: ResourceTypesResponse) => {
        return resourceTypesResponse?.resourceTypes ?? this.defaultFileTypes;
      }),
    );
  }

  private initForm(): void {
    this.fileUploadForm = this.formBuilder.group({
      fileType: [null, Validators.required],
      selectedFile: [null, null, this.isFileValid()],
    });
    this.fileUploadForm.addControl('invoiceAmount', new UntypedFormControl('', this.isValidInvoiceAmount()));
    this.fileUploadForm.get('fileType').valueChanges.subscribe((value) => {
      this.shouldDisplayAmount = this.requiredAmountResourcesTypes.includes(value);
      this.fileUploadForm.get('invoiceAmount').updateValueAndValidity();
    });
  }

  private isFileValid(): AsyncValidatorFn {
    return (): Observable<ValidationErrors> => {
      const error: string = this.filesUploadService.validateFile(this.fileToUpload);
      if (error) {
        this.resetLoadingIndicators();
        return of({ invalidFile: error });
      }
      return of(null);
    };
  }

  private resetLoadingIndicators(): void {
    this.isProcessing = false;
    this.isUploadingFinished = false;
  }

  private async getClaimResourceRequest(): Promise<FileUploadRequest> {
    const { name, content, type } = await this.filesUploadService.readFile(this.fileToUpload);
    const request = {
      resourceName: name,
      resourceType: this.fileUploadForm.controls.fileType.value,
      content: content.split(',')[1],
      mimeType: type,
      sessionId: this.chatSessionId,
    } as FileUploadRequest;

    if (this.fileUploadForm.controls.invoiceAmount?.value) {
      request.invoiceAmount = Number.parseFloat(this.fileUploadForm.controls.invoiceAmount.value);
    }

    return request;
  }

  private updateFormValuesValidity(): void {
    this.changeDetector.detectChanges();
    this.fileUploadForm.controls.selectedFile.updateValueAndValidity();
    this.fileUploadForm.controls.fileType.updateValueAndValidity();
  }

  private isValidInvoiceAmount(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      if (this.requiredAmountResourcesTypes.includes(control.parent.get('fileType').value)) {
        const isValidNumber = !Number.isNaN(control.value) && Number(control.value) > 0;
        if (!control.value || !isValidNumber) {
          return { invalidAmount: 'should be a number' };
        }
      }

      return null;
    };
  }
}
