import { Injectable } from '@angular/core';
import logger from '@next-insurance/logger';

import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { MobileAppEventId } from '../models/mobile-app-send-event.model';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';
import { NavigationService } from './navigation.service';
import { ToastService } from './toast.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(
    private mobileAppService: MobileAppService,
    private mobileAppEventsService: MobileAppEventsService,
    private navigationService: NavigationService,
    private trackingService: TrackingService,
    private toastService: ToastService,
  ) {}

  openOrDownloadFile(fileUrl: string, fileName: string): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({
        eventId: MobileAppEventId.OpenPdfViewer,
        url: fileUrl,
        closeWebview: false,
        fileName,
      });
    } else {
      try {
        this.navigationService.waitForUserInteractionAndDo(() => {
          const openFileInNewTabSucceed = this.openFileInNewTab(fileUrl);

          if (!openFileInNewTabSucceed) {
            const openFileInSameTabSucceed = this.openFileInSameTab(fileUrl);

            if (!openFileInSameTabSucceed) {
              this.downloadFile(fileUrl, fileName);
            }
          }
        });
      } catch (error: any) {
        logger.error('failed to open file', error, { fileName, fileUrl });
      }
    }
  }

  private openFileInNewTab(fileUrl: string): boolean {
    this.trackOpenFile('open-file-in-new-tab');
    const result = window.open(fileUrl);
    return !!result;
  }

  private openFileInSameTab(fileUrl: string): boolean {
    this.trackOpenFile('open-file-in-same-tab');
    const result = window.open(fileUrl, '_self');
    return !!result;
  }

  private downloadFile(fileUrl: string, fileName: string): void {
    this.trackOpenFile('download-file');
    const a = document.createElement('a');
    a.download = fileName;
    a.href = fileUrl;
    a.target = '_blank';
    a.click();
    this.toastService.showToast({
      toastType: ToastType.Success,
      message: 'GENERAL.DOWNLOAD_FILE_MESSAGE',
    });
  }

  trackOpenFile(name: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'file-download-service',
      name,
    });
  }
}
