import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BusinessInformationAssortmentEligibilityResponse } from '../models/business-information-assortment-eligibility-response.model';
import { BusinessInformationAssortmentFlowType } from '../models/business-information-assortment-flow-type.enum';
import { BusinessInformationAssortmentSubmitAnswerRequest } from '../models/business-information-assortment-submit-answer-request.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessInformationAssortmentDataService {
  constructor(private httpClient: HttpClient) {}

  isEligibleForBusinessInformationAssortment(
    assortmentType: BusinessInformationAssortmentFlowType,
  ): Observable<BusinessInformationAssortmentEligibilityResponse> {
    return this.httpClient.get<BusinessInformationAssortmentEligibilityResponse>('/api/business/information-assortment/is-eligible', {
      params: { assortmentType },
    });
  }

  /* istanbul ignore next */
  submitBusinessInformationAssortment(submitAnswersRequest: BusinessInformationAssortmentSubmitAnswerRequest): Observable<void> {
    return this.httpClient.post<void>('/api/business/information-assortment/submit-answer', {
      submitAnswersRequest,
    });
  }
}
