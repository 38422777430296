import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { HelpTip } from '../../core/models/help-tip.enum';

export const HELP_TIP_ATTRIBUTE_NAME = 'data-help-tip';

@Directive({
  selector: '[niHelpTip]',
  standalone: true,
})
export class HelpTipDirective implements OnInit {
  // TODO @shahar - change all the helpTip to arrays (NI-104180)
  @Input('niHelpTip') tips: HelpTip | HelpTip[];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (!this.tips) {
      return;
    }

    const concatTips = Array.isArray(this.tips) ? this.tips.join(',') : this.tips;
    this.renderer.setAttribute(this.elementRef.nativeElement, HELP_TIP_ATTRIBUTE_NAME, concatTips);
  }
}
