import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material/enums';

import { CrossSellTrackingService } from '../../../cross-sell/services/cross-sell-tracking.service';
import { BusinessPropertiesType } from '../../models/business-properties-type.enum';
import { CoverageCheckupService } from '../../services/coverage-checkup.service';
import { CoverageCheckupStepComponent } from '../coverage-checkup-step/coverage-checkup-step.component';

@Component({
  selector: 'ni-coverage-checkup-business-answers-step',
  templateUrl: './coverage-checkup-business-answers-step.component.html',
  styleUrls: ['./coverage-checkup-business-answers-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageCheckupBusinessAnswersStep extends CoverageCheckupStepComponent implements OnInit {
  protected readonly ButtonType = ButtonType;
  isSomeOptionChecked: boolean;
  businessPropertiesAnswers: Record<BusinessPropertiesType, boolean> = {
    [BusinessPropertiesType.Employees]: false,
    [BusinessPropertiesType.Equipment]: false,
    [BusinessPropertiesType.Location]: false,
    [BusinessPropertiesType.Vehicle]: false,
    [BusinessPropertiesType.None]: false,
  };

  constructor(
    private coverageCheckupService: CoverageCheckupService,
    private crossSellTrackingService: CrossSellTrackingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.crossSellTrackingService.trackBusinessPropertiesStepViewed();
    this.autoFillSavedAnswers();
  }

  private autoFillSavedAnswers(): void {
    this.coverageCheckupService.getLastBusinessPropertiesSavedAnswers().subscribe((businessPropertiesAnswers: BusinessPropertiesType[]) => {
      businessPropertiesAnswers.forEach((businessPropertiesType: BusinessPropertiesType) => {
        this.checkAnswer(true, businessPropertiesType);
      });
    });
  }

  onAnswerChecked(isChecked: boolean, businessPropertiesType: BusinessPropertiesType): void {
    this.checkAnswer(isChecked, businessPropertiesType);
    this.crossSellTrackingService.trackBusinessPropertiesAnswerChecked(businessPropertiesType, isChecked);
  }

  private checkAnswer(isChecked: boolean, businessPropertiesType: BusinessPropertiesType): void {
    const businessAnswersCopy = { ...this.businessPropertiesAnswers };
    if (isChecked) {
      // if 'none of the above' is checked - uncheck all other answers
      if (businessPropertiesType === BusinessPropertiesType.None) {
        Object.keys(businessAnswersCopy).forEach((key: BusinessPropertiesType) => {
          businessAnswersCopy[key] = false;
        });
      } else {
        // if any other answer is checked - uncheck 'none of the above'
        businessAnswersCopy[BusinessPropertiesType.None] = false;
      }
    }
    businessAnswersCopy[businessPropertiesType] = isChecked;

    this.isSomeOptionChecked = Object.values(businessAnswersCopy).includes(true);
    this.businessPropertiesAnswers = businessAnswersCopy;
  }

  nextStep(): void {
    this.crossSellTrackingService.trackBusinessPropertiesStepCompleted(this.businessPropertiesAnswers);
    this.coverageCheckupService.saveBusinessPropertiesAnswers(
      Object.keys(this.businessPropertiesAnswers).filter(
        (key: BusinessPropertiesType) => this.businessPropertiesAnswers[key],
      ) as BusinessPropertiesType[],
    );
    this.moveToNextStep.emit();
  }
}
