import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@next-insurance/ng-core';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { QueryParams } from '../models/query-params.enum';
import { UserType } from '../models/user-type.enum';
import { LOCAL_STORAGE } from '../tokens/local-storage.token';

export const returnUrlStorageKey = 'returnUrlWithQueryParams';

@Injectable({
  providedIn: 'root',
})
export class ReturnUrlService {
  private window = inject(WINDOW);
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private cookieService = inject(CookieService);

  handleOktaReturnUrl(): void {
    const returnUrl = new URLSearchParams(this.window.location.search).get(QueryParams.ReturnUrl) || '/home';
    const newUrl = new URL(returnUrl, this.window.location.origin);
    newUrl.searchParams.set(QueryParams.UserType, UserType.ATeam);
    const newUrlString = newUrl.toString();

    this.window.location.replace(newUrlString);
  }

  redirectToReturnUrl(): void {
    const returnUrl = this.getReturnUrl();
    this.removeReturnUrl();
    this.window.location.replace(returnUrl);
  }

  saveReturnUrl(returnUrl: string): void {
    const queryParams = new URLSearchParams(this.window.location.search);
    // TODO: https://nextinsurance.atlassian.net/browse/NI-121611 Refactor to sit inside app initialization service instead of here
    this.checkForMlTokenError(queryParams);
    queryParams.delete(QueryParams.ReturnUrl);
    const returnUrlWithQueryParams = `${returnUrl}${queryParams.size > 0 ? `?${queryParams.toString()}` : ''}`;
    this.localStorage.setItem(returnUrlStorageKey, returnUrlWithQueryParams);
  }

  getReturnUrl(): string {
    return this.localStorage.getItem(returnUrlStorageKey) || '/home';
  }

  private removeReturnUrl(): void {
    this.localStorage.removeItem(returnUrlStorageKey);
  }

  private checkForMlTokenError(queryParams: URLSearchParams): void {
    const mlTokenStatus = queryParams.get(QueryParams.MlTokenStatus) as AuthErrorStatuses;
    if (mlTokenStatus) {
      this.setLoginErrorInCookie(JSON.stringify({ beStatusErrorCode: mlTokenStatus }));
      queryParams.delete(QueryParams.MlTokenStatus);
    }
  }

  private setLoginErrorInCookie(cookieContent: string): void {
    const oneHourExpiration = addHours(Date.now(), 1);
    this.cookieService.set(environment.loginErrorStatusCookieName, cookieContent, {
      domain: this.getHostDomain(),
      expires: oneHourExpiration,
      secure: true,
      path: '/',
    });
  }

  private getHostDomain(): string {
    return EnvConfig.isDev() ? environment.tld : `.${environment.tld}`;
  }
}
