import { EnvConfig, envObject } from './env.config';

const { loadTrackingJS } = require('./snippets/tracking.js');

if (EnvConfig.isStagingOrProduction()) {
  require('./snippets/gtm.js');
}

loadTrackingJS(envObject.trackingUrl);

export const environment = {
  ...envObject,
  production: true,
  enableAngularDebugTools: false,
  enableChangeDetectionLogs: false,
  enableStoreChangesLogs: false,
};
