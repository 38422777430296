import { Injectable } from '@angular/core';

import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class BannersTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackViewBanner(bannerName: string, data: any = {}): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: bannerName,
        name: 'view-banner',
        interactionData: {
          ...data,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCloseBannerClicked(bannerName: string, data: any = {}): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: bannerName,
        name: 'close-banner',
        interactionData: {
          ...data,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackHideBanner(bannerName: string, data: any = {}): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: bannerName,
        name: 'hide-banner',
        interactionData: {
          ...data,
        },
      },
      true,
    );
  }
}
