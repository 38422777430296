import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NIError } from '@next-insurance/errors';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Driver, driver } from 'driver.js';
import { interval, Observable, of } from 'rxjs';
import { catchError, delay, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { AppState } from '../../store';
import { coreSelectors } from '../store/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class DriverJsService {
  driverObj: Driver;

  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.initDriver();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.driverObj.destroy();
    });
  }

  highlightWhenElementIsVisible(
    elementSelector: string,
    description: string,
    timeUntilError: number = 1500,
    afterCloseCallback?: () => void,
  ): Observable<boolean> {
    return this.store.select(coreSelectors.isLoading).pipe(
      first((isLoading) => !isLoading),
      switchMap(() => {
        const intervalTime = 500;
        return interval(intervalTime).pipe(
          map((retryNumber: number) => {
            const isVisible = !!document.querySelector(elementSelector);
            if (isVisible) {
              return true;
            }
            if (timeUntilError > intervalTime * retryNumber) {
              return false;
            }

            throw new NIError('Element not visible');
          }),
          filter((visible: boolean) => !!visible),
          first(),
          delay(600),
          tap(() => {
            this.highlightElement(elementSelector, description, afterCloseCallback);
          }),
          // eslint-disable-next-line @next-insurance/catch-and-throw
          catchError(() => {
            return of(false);
          }),
        );
      }),
    );
  }

  private initDriver(): void {
    this.driverObj = driver({ smoothScroll: true });
  }

  private highlightElement(elementSelector: string, description: string, afterCloseCallback?: () => void): void {
    this.driverObj.highlight({
      element: elementSelector,
      popover: {
        description,
        showButtons: ['next'],
        nextBtnText: this.translateService.instant('GENERAL.GOT_IT'),
        onNextClick: () => this.driverObj.destroy(),
      },
      onDeselected: afterCloseCallback,
    });
  }
}
