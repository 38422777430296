import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { finalize, first, withLatestFrom } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { BusinessIdService } from '../../business/business-id.service';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { TAB_ID } from '../config/global-session-storage-items.config';
import { InteractionType } from '../models/interaction-type.enum';
import { eventNamePattern, UserInteractionEvent, UserInteractionParams } from '../models/user-interaction-params.model';
import { UserType } from '../models/user-type.enum';
import { coreActions } from '../store/core.actions';
import { coreSelectors } from '../store/core.selectors';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { GtmService } from './gtm.service';
import { MobileAppService } from './mobile-app.service';

@Injectable({
  providedIn: 'root',
})
export class UserInteractionsDataService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private mobileAppService: MobileAppService,
    private gtmService: GtmService,
    private cookieService: CookieService,
    private businessIdService: BusinessIdService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}

  sendInteraction(data: UserInteractionParams, sendToGTM = true): void {
    const interactionParams = { ...data, interactionTimestamp: Date.now() };
    this.store.dispatch(coreActions.setIsSubmittingUserInteraction({ isSubmittingUserInteraction: true }));
    if (this.shouldBuildEventName(interactionParams)) {
      interactionParams.eventName = this.buildEventName(interactionParams);
    }
    this.validateEventName(interactionParams.eventName);
    const businessId = this.businessIdService.getBusinessId();
    interactionParams.interactionData = this.buildInteractionData(interactionParams, businessId);

    if (EnvConfig.isDev()) {
      // eslint-disable-next-line no-console
      console.log({ ...interactionParams, businessId });
    }

    if (sendToGTM) {
      this.gtmService.sendUserInteractionAsGTMEvent(interactionParams);
    }

    this.sendUserInteractionAPI(interactionParams, businessId)
      .pipe(
        finalize(() => {
          this.store.dispatch(coreActions.setIsSubmittingUserInteraction({ isSubmittingUserInteraction: false }));
        }),
      )
      .subscribe();
  }

  private buildInteractionData(interactionParams: UserInteractionParams, businessId?: string): Record<string, any> {
    let interactionData: Record<string, any>;
    this.store
      .pipe(select(coreSelectors.getUserType), withLatestFrom(this.store.select(policiesSelectors.isHistoricalUser)), first())
      .subscribe(([userType, isHistoricalUser]: [UserType, boolean]) => {
        interactionData = {
          data: interactionParams.interactionData,
          userType,
          isMobileWebview: this.mobileAppService.isMobileAppWebview(),
          tabId: this.sessionStorage.getItem(TAB_ID),
          ...(businessId && { businessId }),
          ...(businessId && { isHistoricalUser }),
          appName: 'Portal',
          eventName: interactionParams.eventName,
        };
      });

    return interactionData;
  }

  private sendUserInteractionAPI(interactionParams: UserInteractionParams, businessId?: string): Observable<void> {
    const interactionEvent: UserInteractionEvent = {
      interaction_type: interactionParams.interactionType,
      placement: interactionParams.placement,
      name: interactionParams.name,
      interaction_timestamp: Date.now(),
      tracking_id: this.cookieService.get(environment.tidCookieName),
      interaction_data: interactionParams.interactionData,
      ab_test_variant_id: interactionParams.abTestVariant,
      business_id: businessId,
    };

    return this.httpClient.post<void>(`${environment.userInteractionsServiceUrl}/api/user-interaction?source=portal`, interactionEvent);
  }

  private shouldBuildEventName(interactionParams: UserInteractionParams): boolean {
    return !(
      interactionParams.eventName ||
      interactionParams.excludeEventName ||
      interactionParams.interactionType === InteractionType.Debug
    );
  }

  private buildEventName(data: UserInteractionParams): string {
    const placement = this.removeHyphens(data.placement);
    const name = this.removeHyphens(data.name);
    const type = data.interactionType.toUpperCase();
    return `${placement} - ${type} ${name}`;
  }

  private removeHyphens(str: string): string {
    return str.replace(/-/g, ' ');
  }

  private validateEventName(eventName: string): void {
    if (eventName && !eventNamePattern.test(eventName)) {
      logger.error(`User interactions | wrong event name convention - ${eventName}`);
    }
  }
}
