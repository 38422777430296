import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentHistory } from '../billing/models/payment-history.model';
import { ReceiptResponse } from '../core/models/receipt-response';
import { CombinedPaymentDetails } from './models/combined-payment-details.model';
import { FeeType } from './models/fee.enum';
import { PaymentMethodType } from './models/payment-method-details.model';
import { PaymentPlanFees } from './models/payment-plan-fees.model';
import { PaymentPlanFeesResponse } from './models/payment-plan-fees.response';
import { PaymentProviders } from './models/payment-providers.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  updatePaymentMethodByPaymentPlan(token: string, policyId: number, providerTokenId: string): Observable<void> {
    return this.httpClient.post<void>('/api/payment-details/update-payment-method-by-payment-plan', {
      token,
      policyId,
      providerTokenId,
    });
  }

  /* istanbul ignore next */
  getCombinedPaymentDetails(): Observable<CombinedPaymentDetails> {
    return this.httpClient.get<CombinedPaymentDetails>('/api/business/combined-payment-details');
  }

  /* istanbul ignore next */
  getPaymentProviders(): Observable<PaymentProviders> {
    return this.httpClient.get<PaymentProviders>('/api/business/payment-details/payment-providers');
  }

  /* istanbul ignore next */
  getReceiptLink(): Observable<ReceiptResponse> {
    return this.httpClient.get<ReceiptResponse>('/api/business/payment-details/get_payments_receipt');
  }

  /* istanbul ignore next */
  getReceiptLinkByLob(lob: LOB): Observable<ReceiptResponse> {
    return this.httpClient.get<ReceiptResponse>('/api/payments/receipt-link-by-lob', {
      params: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  retryPayment(): Observable<void> {
    return this.httpClient.post<void>('/api/payment-details/retry-payment', {});
  }

  /* istanbul ignore next */
  getRecommendedPaymentMethod(): Observable<{ recommendedMethod: PaymentMethodType }> {
    return this.httpClient.get<{ recommendedMethod: PaymentMethodType }>('/api/payments/recommended-payment-method');
  }

  /* istanbul ignore next */
  getPaymentsHistory(lob: LOB): Observable<PaymentHistory[]> {
    return this.httpClient.get<PaymentHistory[]>('/api/payments/history', {
      params: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  getFeesByBusinessId(): Observable<PaymentPlanFees> {
    return this.httpClient
      .get<PaymentPlanFeesResponse>('/api/payments/fees-by-business-id')
      .pipe(map((response) => this.covertToPaymentPlanFees(response)));
  }

  /* istanbul ignore next */
  getFeesByPolicyId(policyId: number): Observable<PaymentPlanFees> {
    return this.httpClient
      .get<PaymentPlanFeesResponse>('/api/payments/fees-by-policy-id', {
        params: {
          policyId,
        },
      })
      .pipe(map((response) => this.covertToPaymentPlanFees(response)));
  }

  private covertToPaymentPlanFees(response: PaymentPlanFeesResponse): PaymentPlanFees {
    return response.plansWithFees.reduce((acc, plan) => {
      return {
        ...acc,
        [plan.paymentPlanId]: {
          fees: plan.fees.reduce((feesAcc: Record<string, any>, fee) => {
            return {
              ...feesAcc,
              [fee.feeType]:
                fee.feeType === FeeType.TransactionFee
                  ? {
                      percentage: fee.percentage,
                      isActive: fee.isActive,
                    }
                  : { monthlyAmount: fee.monthlyAmount, yearlyAmount: fee.yearlyAmount },
            };
          }, {}),
        },
      };
    }, {});
  }
}
