import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { BusinessIdService } from 'app/business/business-id.service';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { RefreshModalComponent } from '../components/refresh-modal/refresh-modal.component';
import { refreshModalConfig } from '../components/refresh-modal/refresh-modal.config';
import { authConfig } from '../config/auth.config';
import { GtmCategory, GtmEvent } from '../models/gtm-events.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { MobileAppEventId } from '../models/mobile-app-send-event.model';
import { PotentialDataInconsistencyError } from '../models/potential-data-inconsistency-error.enum';
import { DynamicDialogService } from './dynamic-dialog.service';
import { GtmService } from './gtm.service';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';
import { PortalAuthService } from './portal-auth.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  private readonly authErrorCodes = [401, 403];
  private readonly mixedSessionsErrorCode = 441;
  private suspendRefreshedTokenMobileEvent: boolean;

  constructor(
    private dynamicDialogService: DynamicDialogService,
    private trackingService: TrackingService,
    private gtmService: GtmService,
    private mobileAppService: MobileAppService,
    private mobileAppEventsService: MobileAppEventsService,
    private portalAuthService: PortalAuthService,
    private businessIdService: BusinessIdService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    if (this.isAuthenticatedRoute(req)) {
      request = this.addBusinessHeader(req);
    }

    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse && httpEvent.headers?.has('Auth-Tokens-Refreshed')) {
          this.notifyMobileOnRefresh();
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && this.authErrorCodes.concat(this.mixedSessionsErrorCode).includes(err.status)) {
          if (this.shouldIgnoreAuthError(err)) {
            return throwError(() => err);
          }

          this.handleAuthError(err.status);
          return NEVER;
        }

        if (this.isPotentialDataInconsistencyError(err) || this.isNotFoundErrorDueToVersionMismatch(err)) {
          this.openRefreshModal();
          return NEVER;
        }
        this.gtmService.sendGTMInteractionEvent(GtmEvent.Interaction, GtmCategory.Error, request.url, err.error?.niStatusCode);
        return throwError(() => err);
      }),
    );
  }

  private shouldIgnoreAuthError(error: HttpErrorResponse): boolean {
    // Authentication errors or mixed sessions don't affect the public live certificate route,
    // as users can access it even if they're not logged in.
    return (
      error.url.endsWith('api/business/id') ||
      error.url.endsWith('api/business/ids') ||
      (error.url.includes('api/ai-chatbot') && (!error.url.includes('start-session') || !error.url.includes('end-session'))) ||
      error.url.includes('/api/public/authentication/exchange-code-for-tokens')
    );
  }

  private handleAuthError(errorStatus: number): void {
    if (errorStatus === this.mixedSessionsErrorCode) {
      logger.warn('441 error status has been received from server');
      this.openRefreshModal();
    } else {
      this.portalAuthService.logout();
    }
  }

  private isPotentialDataInconsistencyError(err: any): boolean {
    return Object.values(PotentialDataInconsistencyError).includes(err.error?.niStatusCode);
  }

  private isNotFoundErrorDueToVersionMismatch(err: any): boolean {
    return (
      err instanceof HttpErrorResponse &&
      err.status === 404 &&
      err.error?.serverVersion &&
      EnvConfig.getAppVersion() !== err.error.serverVersion
    );
  }

  private notifyMobileOnRefresh(): void {
    const suspendedNotificationTimeout = 5000;
    const shouldSendNotification = !this.suspendRefreshedTokenMobileEvent && this.mobileAppService.isMobileAppWebview();

    if (shouldSendNotification) {
      this.suspendRefreshedTokenMobileEvent = true;
      setTimeout(() => {
        this.suspendRefreshedTokenMobileEvent = false;
      }, suspendedNotificationTimeout);
      this.trackRefreshTokenNotification();
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.RefreshAuthTokens, closeWebview: false });
    }
  }

  private openRefreshModal(): void {
    this.dynamicDialogService.open(RefreshModalComponent, refreshModalConfig);
  }

  private isAuthenticatedRoute(req: HttpRequest<any>): boolean {
    return (
      !authConfig.unauthenticatedRoutesPatterns.some((routePattern) => routePattern.test(req.url)) &&
      authConfig.permittedHostPatterns.some((hostPattern) => hostPattern.test(req.url))
    );
  }

  private addBusinessHeader(req: HttpRequest<any>): HttpRequest<any> {
    const businessId = this.businessIdService.getBusinessId();
    if (!businessId) {
      return req;
    }
    return req.clone({
      setHeaders: {
        [environment.businessIdHeaderName]: businessId,
      },
    });
  }

  private trackRefreshTokenNotification(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'mobile-web-view',
      name: 'refresh-tokens-mobile-notification',
      eventName: 'mobile web view notification - VIEW refresh token notification',
    });
  }
}
