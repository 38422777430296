import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AfterPurchasePageComponent } from './after-purchase-page/components/after-purchase-page.component';
import { businessGuard } from './core/guards/business.guard';
import { businessIdGuard } from './core/guards/business-id.guard';
import { userTypeGuard } from './core/guards/user-type.guard';
import { crossSellMobileGuard } from './cross-sell-mobile/guards/cross-sell-mobile.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [userTypeGuard],
    children: [
      {
        path: 'public/certificates',
        canActivate: [businessGuard],
        loadChildren: () =>
          import('./certificates/unauthenticated/certificates-unauthenticated.module').then((m) => m.CertificatesUnauthenticatedModule),
      },
    ],
  },
  {
    path: 'business-selection',
    loadComponent: () =>
      import('./business-selection-page/business-selection-page.component').then((c) => c.BusinessSelectionPageComponent),
  },
  {
    path: '',
    canActivate: [businessIdGuard, userTypeGuard],
    children: [
      {
        path: '',
        canActivate: [businessGuard],
        children: [
          {
            path: 'home',
            loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
          },
          {
            path: 'claims',
            loadChildren: () => import('./claims/claims.module').then((m) => m.ClaimsModule),
          },
          {
            path: 'business',
            loadChildren: () => import('./business/business.module').then((m) => m.BusinessModule),
          },
          {
            path: 'billing',
            loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
          },
          {
            path: 'followers',
            loadChildren: () => import('./followers/followers.module').then((m) => m.FollowersModule),
          },
          {
            path: 'policy-change',
            loadChildren: () => import('./business-change/business-change.module').then((m) => m.BusinessChangeModule),
          },
          {
            path: 'payment',
            loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
          },
          {
            path: 'certificates',
            loadChildren: () =>
              import('./certificates/authenticated/certificates-authenticated.module').then((m) => m.CertificatesAuthenticatedModule),
          },
          {
            path: 'coverage',
            loadChildren: () => import('./coverage-page/coverage.module').then((m) => m.CoverageModule),
          },
          {
            path: 'document-center',
            loadChildren: () => import('./document-center/document-center.module').then((m) => m.DocumentCenterModule),
          },
          {
            path: 'cross-sell-mobile',
            canActivate: [crossSellMobileGuard],
            loadComponent: () => null,
          },
          {
            path: 'rewrite',
            loadChildren: () => import('./cancel-rewrite/cancel-rewrite.module').then((m) => m.CancelRewriteModule),
          },
          {
            path: 'mobile-app-preload',
            loadChildren: () => import('./mobile-app-preload/mobile-app-preload.module').then((m) => m.MobileAppPreloadModule),
          },
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'easy-login',
    loadChildren: () => import('./developer-login/developer-login.module').then((m) => m.DeveloperLoginModule),
  },
  {
    path: 'agents',
    loadChildren: () => import('./agents/agents.module').then((m) => m.AgentsModule),
  },
  {
    path: 'mobile-app-update',
    loadChildren: () => import('./force-mobile-app-update/force-mobile-app-update.module').then((m) => m.ForceMobileAppUpdateModule),
  },
  {
    path: 'chatbot',
    loadChildren: () => import('./chatbot/chatbot.module').then((m) => m.ChatbotModule),
  },
  {
    path: 'empty-page',
    loadChildren: () => import('./empty-page/empty-page.module').then((m) => m.EmptyPageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'public/after-purchase',
    loadComponent: () => AfterPurchasePageComponent,
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
