import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { PortalAuthService } from '../../core/services/portal-auth.service';
import { TrackingService } from '../../core/services/tracking.service';

@Component({
  selector: 'ni-after-purchase-page',
  templateUrl: './after-purchase-page.component.html',
  styleUrls: ['./after-purchase-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class AfterPurchasePageComponent implements OnInit {
  private authService = inject(PortalAuthService);
  private trackingService = inject(TrackingService);

  ngOnInit(): void {
    this.trackAfterPurchasePageView();
  }

  loginClicked(): void {
    this.trackLoginClicked();
    this.authService.logout();
  }

  private trackAfterPurchasePageView(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: 'after-purchase-page',
        name: 'after-purchase-page-loaded',
      },
      true,
    );
  }

  private trackLoginClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'after-purchase-page',
        name: 'login-button',
      },
      true,
    );
  }
}
