import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BusinessService } from '../../../../../business/business.service';
import { businessSelectors } from '../../../../../business/store/business.selectors';
import { ZendeskIssueTag } from '../../../../../core/models/zendesk-issue-tag.enum';
import { CommonConfigDataService } from '../../../../../core/services/common-config.data.service';
import { FeatureFlagsService } from '../../../../../core/services/feature-flags.service';
import { ToastService } from '../../../../../core/services/toast.service';
import { VerificationV2DataService } from '../../../../../core/services/verification-v2.data.service';
import { ZendeskService } from '../../../../../core/services/zendesk.service';
import { SESSION_STORAGE } from '../../../../../core/tokens/session-storage.token';
import { AppState } from '../../../../../store';
import { OnboardingTrackingService } from '../../../../services/onboarding-tracking.service';
import { OnboardingVerificationStepComponent } from '../onboarding-verification-step.component';

@Component({
  selector: 'ni-onboarding-email-verification-step',
  templateUrl: './onboarding-email-verification-step.component.html',
  styleUrls: ['../onboarding-verification-step.component.scss', '../../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingEmailVerificationStepComponent extends OnboardingVerificationStepComponent implements OnInit {
  readonly verificationCodeSentStorageKey = 'onboardingVerificationCodeSent';
  readonly stepSettingsCompletedHeader = 'VERIFICATION.COMPLETED.HEADER';
  readonly stepSettingsInitialHeader = 'VERIFICATION.EMAIL.INITIAL.HEADER';
  readonly resendSuccessMessage = 'VERIFICATION.RESEND_CODE_SUCCESS.EMAIL';

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected commonConfigDataService: CommonConfigDataService,
    protected fb: UntypedFormBuilder,
    protected verificationV2DataService: VerificationV2DataService,
    protected store: Store<AppState>,
    protected featureFlagsService: FeatureFlagsService,
    protected router: Router,
    protected toastService: ToastService,
    @Inject(SESSION_STORAGE) protected sessionStorage: Storage,
    private onboardingTrackingService: OnboardingTrackingService,
    private zendeskService: ZendeskService,
    private businessService: BusinessService,
  ) {
    super(
      changeDetectorRef,
      commonConfigDataService,
      fb,
      verificationV2DataService,
      store,
      featureFlagsService,
      sessionStorage,
      router,
      toastService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getCurrIdentityInput$(): Observable<string> {
    return this.store.select(businessSelectors.getEmailAddress);
  }

  trackVerificationEditClicked(): void {
    this.onboardingTrackingService.trackEmailVerificationEditEmailClicked();
  }

  trackVerificationLanding(): void {
    this.onboardingTrackingService.trackEmailVerificationLanding();
  }

  trackVerificationResendCodeClicked(): void {
    this.onboardingTrackingService.trackEmailVerificationResendCodeClicked();
  }

  trackVerificationResendCodeResult(isSuccess: boolean, niStatusCode?: string): void {
    this.onboardingTrackingService.trackEmailVerificationResendCodeResult(isSuccess, niStatusCode);
  }

  trackVerificationSendCodeResult(isSuccess: boolean, niStatusCode?: string): void {
    this.onboardingTrackingService.trackEmailVerificationSendCodeResult(isSuccess, niStatusCode);
  }

  trackVerificationValidateCodeResult(isSuccess: boolean, niStatusCode?: string): void {
    this.onboardingTrackingService.trackEmailVerificationValidateCodeResult(isSuccess, niStatusCode);
  }

  loadIsIdentityVerified(): void {
    this.businessService.loadIsEmailVerified().subscribe();
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.ChatEmailVerificationIssue]);
  }
}
