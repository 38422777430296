import { inject, Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { BusinessLocation } from '../../business/models/business-location.model';
import { Policy } from '../../policies/models/policy.model';
import { PolicyStatus } from '../../policies/models/policy-status.enum';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { PermittedChanges, RichChangesType } from '../models/business-change.enum';
import { businessChangeActions } from '../store/business-change.actions';
import { businessChangeSelectors } from '../store/business-change.selectors';
import { BusinessChangeDataService } from './business-change.data.service';

@Injectable({
  providedIn: 'root',
})
export class PermittedChangesService {
  private businessChangeDataService = inject(BusinessChangeDataService);
  private permittedChanges: PermittedChanges;

  constructor(private store: Store<AppState>) {
    this.store.select(businessChangeSelectors.getPermittedChanges).subscribe((permittedChanges: PermittedChanges) => {
      this.permittedChanges = permittedChanges;
    });
  }

  loadPermittedChanges(): Observable<PermittedChanges> {
    this.store.dispatch(businessChangeActions.setIsLoadingPermittedChanges());

    return this.businessChangeDataService.getPermittedChanges().pipe(
      tap((permittedChanges) => this.store.dispatch(businessChangeActions.setPermittedChanges({ permittedChanges }))),
      catchErrorAndLog(() => {
        this.store.dispatch(businessChangeActions.setPermittedChanges({ permittedChanges: {} }));
        return of(null);
      }),
    );
  }

  isPermitted(changeType: RichChangesType, lob?: LOB): boolean {
    if (lob) {
      return this.isPermittedPerPolicy(changeType, lob);
    }
    return this.isPermittedPerBusiness(changeType);
  }

  waitForPermittedChanges(): Observable<boolean> {
    return this.store.pipe(
      select(businessChangeSelectors.isLoadingPermittedChanges),
      filter((isLoadingPermittedChanges: boolean) => !isLoadingPermittedChanges),
      first(),
    );
  }

  getPolicyForRichChange(changeType: RichChangesType): Observable<Policy> {
    return combineLatest([
      this.store.select(policiesSelectors.getPolicies),
      this.store.select(businessChangeSelectors.getPermittedChanges),
    ]).pipe(
      map(([policies, permittedChanges]: [Policy[], PermittedChanges]) => {
        return policies.find(
          (policy: Policy) =>
            permittedChanges[policy.lob]?.includes(changeType) &&
            [PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus),
        );
      }),
    );
  }

  getPermittedLOBsForRichChange(changeType: RichChangesType): Observable<LOB[]> {
    return this.store.select(businessChangeSelectors.getPermittedChanges).pipe(
      map((permittedChanges: PermittedChanges) => {
        return Object.entries(permittedChanges)
          .filter(([, changes]: [LOB, RichChangesType[]]) => changes.includes(changeType))
          .map(([lob]: [LOB, RichChangesType[]]) => lob);
      }),
    );
  }

  isPermittedPerLocation(location: BusinessLocation, changeType: RichChangesType): boolean {
    return location.coveringLobs?.some((lob: LOB) => this.isPermittedPerPolicy(changeType, lob));
  }

  private isPermittedPerBusiness(changeType: RichChangesType): boolean {
    return Object.values(this.permittedChanges).flat().includes(changeType);
  }

  private isPermittedPerPolicy(changeType: RichChangesType, lob: LOB): boolean {
    return this.permittedChanges[lob] && this.permittedChanges[lob].includes(changeType);
  }
}
