import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LOCAL_STORAGE } from '../core/tokens/local-storage.token';

@Injectable({
  providedIn: 'root',
})
export class BusinessIdService {
  private readonly LAST_SELECTED_BUSINESS_KEY = 'lastSelectedBusinessId';
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private businessIdSubject$ = new BehaviorSubject<string>(undefined);
  private businessId$ = this.businessIdSubject$.asObservable();

  getLastSelectedBusinessId(): string {
    return this.localStorage.getItem(this.LAST_SELECTED_BUSINESS_KEY);
  }

  setBusinessId(businessId: string): void {
    this.localStorage.setItem(this.LAST_SELECTED_BUSINESS_KEY, businessId);
    this.businessIdSubject$.next(businessId);
  }

  removeBusinessId(): void {
    this.businessIdSubject$.next('');
  }

  getBusinessId(): string {
    return this.businessIdSubject$.getValue();
  }

  getBusinessId$(): Observable<string> {
    return this.businessId$;
  }
}
