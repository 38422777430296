import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NiAddressApiModule } from '@next-insurance/ng-core';
import { AchPaymentModule } from '@next-insurance/ni-material';
import { NiButtonRbComponent } from '@next-insurance/ni-material/ni-button-rb';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { AlertBannerComponent } from '../shared/components/alert-banner/alert-banner.component';
import { BillingNotificationModalComponent } from '../shared/components/billing-notification-modal/billing-notification-modal.component';
import { LanguageSwitchComponent } from '../shared/components/language-switch/language-switch.component';
import { MenuComponent } from '../shared/components/menu/menu.component';
import { MenuOptionComponent } from '../shared/components/menu/menu-option/menu-option.component';
import { ReferralProgramButtonComponent } from '../shared/components/referral-program/referral-program-button/referral-program-button.component';
import { ClickOutsideDirective } from '../shared/directives/click-outside.directive';
import { HelpTipDirective } from '../shared/directives/help-tip.directive';
import { HolidayDecorDirective } from '../shared/directives/holiday-decor.directive';
import { NiWideDesktopDirective } from '../shared/directives/ni-wide-desktop.directive';
import { AsPipe } from '../shared/pipes/as.pipe';
import { NiCurrencyPipe } from '../shared/pipes/currency.pipe';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { AchFailedPaymentBannerComponent } from './components/ach-failed-payment-banner/ach-failed-payment-banner.component';
import { AchRecommendationBannerComponent } from './components/ach-recommendation-banner/ach-recommendation-banner.component';
import { CreditCardFailedPaymentAlertBannerComponent } from './components/credit-card-failed-payment-alert-banner/credit-card-failed-payment-alert-banner.component';
import { FailedPaymentBannerComponent } from './components/failed-payment-banner/failed-payment-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileAppBannerComponent } from './components/mobile-app-banner/mobile-app-banner.component';
import { NotProprietaryPoliciesBannerComponent } from './components/not-proprietary-policies-banner/not-proprietary-policies-banner.component';
import { ExpandableMenuItemComponent } from './components/portal-navigation/expandable-menu-item/expandable-menu-item.component';
import { NavigationMenuComponent } from './components/portal-navigation/navigation-menu/navigation-menu.component';
import { NavigationTabsComponent } from './components/portal-navigation/navigation-tabs/navigation-tabs.component';
import { PortalNavigationComponent } from './components/portal-navigation/portal-navigation.component';
import { RefreshModalComponent } from './components/refresh-modal/refresh-modal.component';
import { UnsupportedBrowsersBannerComponent } from './components/unsupported-browsers-banner/unsupported-browsers-banner.component';
import { UrgentUserMessageBannerComponent } from './components/urgent-user-message-banner/urgent-user-message-banner.component';
import { WcComplianceBannerComponent } from './components/wc-compliance-banner/wc-compliance-banner.component';

@NgModule({
  declarations: [
    RefreshModalComponent,
    HeaderComponent,
    UrgentUserMessageBannerComponent,
    UnsupportedBrowsersBannerComponent,
    CreditCardFailedPaymentAlertBannerComponent,
    NavigationTabsComponent,
    NavigationMenuComponent,
    PortalNavigationComponent,
    NotProprietaryPoliciesBannerComponent,
    MobileAppBannerComponent,
    ExpandableMenuItemComponent,
    WcComplianceBannerComponent,
    FailedPaymentBannerComponent,
    AchFailedPaymentBannerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AlertBannerComponent,
    MenuComponent,
    NiAddressApiModule.options(null, true),
    BillingNotificationModalComponent,
    AchPaymentModule,
    ClickOutsideDirective,
    LocalDatePipe,
    ReferralProgramButtonComponent,
    NiCurrencyPipe,
    ClickOutsideDirective,
    HelpTipDirective,
    HolidayDecorDirective,
    NiWideDesktopDirective,
    MenuOptionComponent,
    LanguageSwitchComponent,
    FaIconComponent,
    OverlayPanelModule,
    AsPipe,
    NiButtonRbComponent,
    TranslateModule,
    AchRecommendationBannerComponent,
  ],
  exports: [RefreshModalComponent, HeaderComponent, MobileAppBannerComponent, BillingNotificationModalComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. It should be loaded in the AppModule only');
    }
  }
}
