export enum TargetApp {
  Portal = 'PORTAL',
  Trex = 'TREX',
}

export interface ExchangeCodeResponse {
  targetApp: TargetApp;
  hasEligibleBusiness: boolean;
  showBusinessSelectionPage: boolean;
}
