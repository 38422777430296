export enum MobileAppReceiveEventId {
  Navigate = 'NAVIGATE_TO',
  OpenChatbot = 'OPEN_CHAT_BOT',
  CloseChatbot = 'CLOSE_CHAT_BOT',
  MinimizeChatbot = 'MINIMIZE_CHAT_BOT',
}

export type OpenChatbot = {
  mobileEventId: MobileAppReceiveEventId.OpenChatbot;
};

export type NavigateWithRouter = {
  mobileEventId: MobileAppReceiveEventId.Navigate;
  path: string;
};

export type CloseChatbot = {
  mobileEventId: MobileAppReceiveEventId.CloseChatbot;
  shouldOpenSurvey: boolean;
};

export type MinimizeChatbot = {
  mobileEventId: MobileAppReceiveEventId.MinimizeChatbot;
};

export type MobileAppReceivedEvent = OpenChatbot | NavigateWithRouter | CloseChatbot | MinimizeChatbot;
