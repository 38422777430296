import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import logger from '@next-insurance/logger';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { BusinessIdService } from '../../business/business-id.service';
import { AppState } from '../../store';
import { PortalAuthService } from '../services/portal-auth.service';
import { coreSelectors } from '../store/core.selectors';

let store: Store<AppState>;

export const businessIdGuard: CanActivateFn = (): Observable<boolean> => {
  store = inject(Store<AppState>);
  const authService = inject(PortalAuthService);
  const businessId = inject(BusinessIdService).getBusinessId();

  return store.select(coreSelectors.isUserLoggedIn).pipe(
    first(),
    map((isUserLoggedIn) => {
      if (!isUserLoggedIn || !businessId) {
        logger.warn('businessIdGuard: user is trying to load a page without being logged in');
        authService.logout();
        return false;
      }

      return true;
    }),
  );
};
