import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NiMobileOnlyDirective } from '@next-insurance/ni-material/ni-device-only-directives';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Language } from '../../../core/enums/language.enum';
import { LmsSerials } from '../../../core/models/lms-serials.enum';
import { LanguageTrackingService } from '../../../core/services/language-tracking.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { coreSelectors } from '../../../core/store/core.selectors';
import { LanguageSwitchComponent } from '../language-switch/language-switch.component';
import { ReferralProgramButtonComponent } from '../referral-program/referral-program-button/referral-program-button.component';

@Component({
  selector: 'ni-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    LanguageSwitchComponent,
    TranslateModule,
    AsyncPipe,
    ReferralProgramButtonComponent,
    NiMobileOnlyDirective,
  ],
  providers: [DialogService],
})
export class FooterComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  hideLogo: boolean;
  private navEndSubscription: Subscription;

  isUserLoggedIn$ = inject(Store).select(coreSelectors.isUserLoggedIn);
  private languageTrackingService = inject(LanguageTrackingService);
  private navigationService = inject(NavigationService);
  private router = inject(Router);
  private cdr = inject(ChangeDetectorRef);

  navigateToTermsOfUse(): void {
    this.navigationService.navigateByLMS(LmsSerials.TermsOfUse);
  }

  navigateToPrivacyPolicy(): void {
    this.navigationService.navigateByLMS(LmsSerials.PrivacyPolicy);
  }

  languageChanged(lang: Language): void {
    this.languageTrackingService.trackFooterLanguageSwitch(lang);
  }

  ngOnInit(): void {
    this.setLogoHiddenByRoute(this.router.url);

    this.navEndSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: Event | RouterEvent) => {
        this.setLogoHiddenByRoute((event as RouterEvent).url);
        this.cdr.detectChanges();
      });
  }

  private setLogoHiddenByRoute(path: string): void {
    this.hideLogo = !path.includes('login/authentication');
  }

  ngOnDestroy(): void {
    this.navEndSubscription.unsubscribe();
  }
}
