import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material/enums';

import { MobileAppService } from '../../../../core/services/mobile-app.service';
import { StepStatus } from '../../../models/step-status.enum';
import { OnboardingTrackingService } from '../../../services/onboarding-tracking.service';
import { OnboardingStepComponent } from '../onboarding-step/onboarding-step.component';

@Component({
  selector: 'ni-onboarding-download-app-step',
  templateUrl: './onboarding-download-app-step.component.html',
  styleUrls: ['./onboarding-download-app-step.component.scss', '../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingDownloadAppStepComponent extends OnboardingStepComponent implements OnInit {
  ButtonType = ButtonType;
  stepSettings: {
    [status in StepStatus]: {
      header: string;
      icon: string;
    };
  } = {
    [StepStatus.Initial]: {
      header: 'ONBOARDING.DOWNLOAD_APP.INITIAL.HEADER',
      icon: '/assets/img/phone-holds-certificate.svg',
    },
    [StepStatus.Completed]: {
      header: 'ONBOARDING.DOWNLOAD_APP.COMPLETED.HEADER',
      icon: '/assets/img/phone-holds-certificate.svg',
    },
  };

  constructor(
    private onboardingTrackingService: OnboardingTrackingService,
    private mobileAppService: MobileAppService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this.onboardingTrackingService.trackDownloadAppLanding();
  }

  downloadApp(): void {
    this.onboardingTrackingService.trackDownloadApp();
    this.mobileAppService.downloadApp();
    this.completeStep();
  }

  onSkip(): void {
    this.onboardingTrackingService.trackSkipDownloadApp();
    this.moveToNextStep.emit();
  }
}
