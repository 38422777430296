import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FeedbackType } from '@next-insurance/ni-material/enums';
import { NiSystemFeedbackComponent } from '@next-insurance/ni-material/ni-system-feedback';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';

import { MobileAppEventId } from '../../../core/models/mobile-app-send-event.model';
import { FeatureFlagsService } from '../../../core/services/feature-flags.service';
import { MobileAppEventsService } from '../../../core/services/mobile-app-events.service';
import { ToastService } from '../../../core/services/toast.service';
import { Policy } from '../../../policies/models/policy.model';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { PaymentProvider } from '../../enums/payment-provider.enum';
import { PaymentMethodType } from '../../models/payment-method-details.model';
import { PaymentProviders } from '../../models/payment-providers.model';
import { PaymentDataService } from '../../payment.data.service';
import { PaymentService } from '../../payment.service';
import { BraintreePaymentFormComponent } from '../../payment-form/braintree-payment-form/braintree-payment-form.component';
import { StripeAchPaymentFormComponent } from '../../payment-form/stripe-ach-payment-form/stripe-ach-payment-form.component';
import { StripeCreditCardFormComponent } from '../../payment-form/stripe-payment-form/stripe-credit-card-form.component';
import { PaymentTrackingService } from '../../payment-tracking.service';
import { PaymentMethodTypeButtonsComponent } from '../payment-method-type-buttons/payment-method-type-buttons.component';
import { UpdatePaymentModalData } from './update-payment-modal-data.model';

@Component({
  selector: 'ni-update-payment-modal',
  templateUrl: './update-payment-modal.component.html',
  styleUrls: ['./update-payment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    LoaderComponent,
    NiSystemFeedbackComponent,
    StripeCreditCardFormComponent,
    BraintreePaymentFormComponent,
    PaymentMethodTypeButtonsComponent,
    AsyncPipe,
    TranslateModule,
    StripeAchPaymentFormComponent,
  ],
})
export class UpdatePaymentModalComponent implements OnInit {
  modalData: UpdatePaymentModalData;
  paymentProviderDetails: PaymentProviders;
  paymentMethodTypeOptions: PaymentMethodType[] = [];
  selectedPaymentMethod: PaymentMethodType;
  shouldDisableButton$ = new BehaviorSubject(true);
  isPaymentSubmitting = false;
  policy$: Observable<Policy>;
  protected readonly PaymentProviderEnum = PaymentProvider;
  protected readonly FeedbackType = FeedbackType;
  protected readonly PaymentMethodType = PaymentMethodType;
  isAchRecommended: boolean;
  transactionFee: number;
  featureFlagsService = inject(FeatureFlagsService);

  constructor(
    private paymentService: PaymentService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private dynamicDialogConfig: DynamicDialogConfig<UpdatePaymentModalData>,
    private dialogRef: DynamicDialogRef,
    private paymentTrackingService: PaymentTrackingService,
    private paymentDataService: PaymentDataService,
    private mobileAppEventsService: MobileAppEventsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.paymentTrackingService.trackViewUpdatePaymentModal();
    this.listenToCloseEvent();
    this.modalData = this.dynamicDialogConfig.data;
    this.policy$ = this.store.pipe(select(policiesSelectors.firstNextPolicy));
    this.handlePaymentProviderDetails();
    this.transactionFee = this.modalData.transactionFee;
  }

  onSubmitStripeCreditCard($event: { token: string; providerTokenId: string }, policy: Policy): void {
    this.onSubmit($event.token, PaymentProvider.Stripe, PaymentMethodType.CreditCard, policy.policyId, $event.providerTokenId);
  }

  onSubmitBraintreeCreditCard(nonce: string, policy: Policy): void {
    this.onSubmit(nonce, PaymentProvider.Braintree, PaymentMethodType.CreditCard, policy.policyId);
  }

  onSubmitStripeACH($event: { token: string; providerTokenId: string }, policy: Policy): void {
    this.onSubmit($event.token, PaymentProvider.Stripe, PaymentMethodType.BankAccount, policy.policyId, $event.providerTokenId);
  }

  onMethodTypeSelected(paymentMethodType: PaymentMethodType): void {
    this.paymentTrackingService.trackSelectPaymentMethodType(this.selectedPaymentMethod, paymentMethodType);
    this.selectedPaymentMethod = paymentMethodType;
  }

  onErrorOccurredDuringUpdatePayment(errorMessage: string): void {
    this.onPaymentUpdateFailure(null, errorMessage);
  }

  private async refreshPaymentProvidersDetails(): Promise<void> {
    this.paymentProviderDetails = await this.paymentService.getPaymentProviderDetails();
    this.cdr.markForCheck();
  }

  private async handlePaymentProviderDetails(): Promise<void> {
    this.paymentProviderDetails = await this.paymentService.getPaymentProviderDetails();
    this.isAchRecommended = this.paymentProviderDetails.defaultPaymentMethod === PaymentMethodType.BankAccount;
    this.selectedPaymentMethod = this.paymentProviderDetails.defaultPaymentMethod ?? this.dynamicDialogConfig.data.paymentMethodType;
    this.paymentMethodTypeOptions = this.sortPaymentMethodsBySelectedPayment();
    this.cdr.markForCheck();
  }

  private onSubmit(
    token: string,
    paymentProvider: PaymentProvider,
    paymentMethodType: PaymentMethodType,
    policyId: number,
    providerTokenId?: string,
  ): void {
    this.isPaymentSubmitting = true;
    this.cdr.markForCheck();
    this.paymentTrackingService.trackSubmitUpdatePaymentMethod(paymentProvider, paymentMethodType);

    this.paymentDataService
      .updatePaymentMethodByPaymentPlan(token, policyId, providerTokenId)
      .pipe(
        tap(() => {
          this.onPaymentUpdateSucceeded();
        }),
        catchErrorAndLog((error) => {
          this.paymentTrackingService.trackUpdatePaymentMethod(false);
          this.onPaymentUpdateFailure(error.error?.niStatusCode);
          return EMPTY;
        }),
        finalize(() => {
          this.isPaymentSubmitting = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  private async onPaymentUpdateFailure(errorStatusCode?: string, errorMessage?: string): Promise<void> {
    const message = errorMessage || (errorStatusCode ? `PAYMENT.ERRORS.${errorStatusCode}` : null);
    if (message) {
      this.toastService.showToast({
        toastType: ToastType.Error,
        message,
        isAlreadyTranslated: !errorStatusCode,
      });
    } else {
      this.toastService.showGeneralErrorToast();
    }

    await this.refreshPaymentProvidersDetails();
  }

  private onPaymentUpdateSucceeded(): void {
    this.paymentTrackingService.trackUpdatePaymentMethod(true);
    if (this.modalData.shouldCloseMobileBanner && this.mobileAppEventsService.shouldSendPaymentRefreshEvent()) {
      this.mobileAppEventsService.notifyMobile({
        eventId: MobileAppEventId.LastFailedPaymentHandledEvent,
        closeWebview: true,
      });
    } else {
      this.paymentService.loadCombinedPaymentDetails().subscribe();
    }
    this.dialogRef.close(true);
    this.modalData.onPaymentUpdateFinished();
  }

  private listenToCloseEvent(): void {
    this.dialogRef.onClose.pipe(first()).subscribe((isActionTaken: boolean) => {
      if (!isActionTaken) {
        this.paymentTrackingService.trackCloseUpdatePaymentModal();
      }
    });
  }

  private sortPaymentMethodsBySelectedPayment(): PaymentMethodType[] {
    return (
      this.paymentProviderDetails &&
      (Object.keys(this.paymentProviderDetails.allowedMethods).sort((paymentMethodTypeA, paymentMethodTypeB) => {
        if (paymentMethodTypeA === this.selectedPaymentMethod) return -1;
        if (paymentMethodTypeB === this.selectedPaymentMethod) return 1;
        return paymentMethodTypeA.localeCompare(paymentMethodTypeB);
      }) as PaymentMethodType[])
    );
  }
}
