import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { HidePhoneNumberPipe } from '../../shared/pipes/hide-phone-number.pipe';

interface ToastOptions {
  toastType: ToastType;
  message?: string;
  title?: string;
  isAlreadyTranslated?: boolean;
  translateParams?: any;
  options?: Partial<{ timeOut: number }>;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private hidePhoneNumberPipe = inject(HidePhoneNumberPipe);

  showToast(toastOptions: ToastOptions): void {
    let { message, title } = toastOptions;
    const { isAlreadyTranslated, translateParams, toastType, options } = toastOptions;

    if (!isAlreadyTranslated) {
      message = message && this.translateService.instant(message, translateParams);
      title = title && this.translateService.instant(title, translateParams);
    }

    this.messageService.add({
      severity: toastType,
      id: toastType,
      summary: title,
      detail: message,
      closable: true,
      life: options?.timeOut || 6000,
    });
  }

  showGeneralErrorToast(): void {
    this.showToast({
      toastType: ToastType.Error,
      message: this.hidePhoneNumberPipe.transform('GENERAL.ERRORS.TOAST.CONTENT'),
      title: 'GENERAL.ERRORS.TOAST.HEADER',
    });
  }

  showLoadingErrorToast(): void {
    this.showToast({
      toastType: ToastType.Error,
      message: this.hidePhoneNumberPipe.transform('GENERAL.ERRORS.LOADING.CONTENT'),
      title: 'GENERAL.ERRORS.LOADING.HEADER',
    });
  }
}
