import { inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { NiSurvicateService, SurveyTraits, SurvicateEventType } from '@next-insurance/ng-core';

import { BusinessIdService } from '../../business/business-id.service';
import { Business } from '../../business/models/business.model';
import { Policy } from '../../policies/models/policy.model';
import { PoliciesService } from '../../policies/services/policies.service';
import { InteractionType } from '../models/interaction-type.enum';
import { SurvicateBusinessOrigin } from '../models/survicate-business-origin.enum';
import { UserInteractionsDataService } from './user-interactions.data.service';

@Injectable({
  providedIn: 'root',
})
export class SurvicateService {
  private niSurvicate = inject(NiSurvicateService);
  private userInteractionsService = inject(UserInteractionsDataService);
  private businessIdService = inject(BusinessIdService);

  init(trackingId: string): void {
    this.updateTraits({ trackingId });
    this.listenToSurveyDisplayedEvent();
  }

  updateTraitsByBusiness(business: Business): void {
    const businessId = this.businessIdService.getBusinessId();
    this.updateTraits({
      email: business.emailAddress,
      businessId,
      businessOrigin: this.getBusinessOriginTraits(business),
    });
  }

  private getBusinessOriginTraits(business: Business): SurvicateBusinessOrigin {
    if (business.isBusinessLinkedToExternalAgent) {
      return SurvicateBusinessOrigin.ExternalAgent;
    }
    if (business.affiliateData) {
      return SurvicateBusinessOrigin.Affiliate;
    }
    return SurvicateBusinessOrigin.Direct;
  }

  updateTraitsByPolicies(policies: Policy[]): void {
    const isHistoricalUser = PoliciesService.isHistoricalUser(policies);
    this.updateTraits({ isHistoricalUser: String(isHistoricalUser) });
  }

  updateChatSessionIdTrait(sessionId: string): void {
    this.updateTraits({ chatSessionId: sessionId });
  }

  listenToSurveyDisplayedEvent(): void {
    this.niSurvicate.addEventListener(SurvicateEventType.SurveyDisplayed, (surveyId: string, surveyName: string) => {
      this.userInteractionsService.sendInteraction({
        interactionType: InteractionType.Debug,
        placement: 'survicate',
        name: 'survey-displayed',
        interactionData: { surveyId, surveyName },
      });
    });
  }

  private async updateTraits(traits: SurveyTraits): Promise<void> {
    try {
      await this.niSurvicate.setVisitorTraits(traits);
    } catch {}
  }

  showSurvey(surveyName: string): void {
    const openSurveySucceeded = this.niSurvicate.invokeEvent(surveyName);
    if (!openSurveySucceeded) {
      logger.error('Survey invocation method is not available.');
    }
  }
}
