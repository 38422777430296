import { Injectable } from '@angular/core';
import { AchFailureCategory } from '@next-insurance/core';

import { CreditCardFailedPaymentBannerType } from '../models/credit-card-failed-payment-banner-type.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class FailedPaymentBannerTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackCreditCardFailedPaymentBannerClickEditCard(bannerType: CreditCardFailedPaymentBannerType): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'credit-card-failed-payment-banner',
        name: 'edit-card-button',
        interactionData: {
          bannerType,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCreditCardFailedPaymentBannerClickRetry(bannerType: CreditCardFailedPaymentBannerType): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'credit-card-failed-payment-banner',
        name: 'retry-payment-button',
        interactionData: {
          bannerType,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackAchFailedPaymentBannerClickGoToBilling(failureCategory: AchFailureCategory): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'ach-failed-payment-banner',
        name: 'go-to-billing-button',
        interactionData: {
          failureCategory,
        },
      },
      true,
    );
  }
}
