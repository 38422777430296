import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { NiButtonRbComponent } from '@next-insurance/ni-material/ni-button-rb';
import { TranslateModule } from '@ngx-translate/core';

import { LmsSerials } from '../../../../core/models/lms-serials.enum';
import { DynamicDialogService } from '../../../../core/services/dynamic-dialog.service';
import { NavigationService } from '../../../../core/services/navigation.service';
import { CoverageCheckupModalComponent } from '../../../../coverage-checkup/components/coverage-checkup-modal/coverage-checkup-modal.component';
import { LOB_HI } from '../../../config/external-cross-sell-links.config';
import { CrossSellPolicyStatus } from '../../../models/cross-sell-policy-status.enum';
import { CrossSellTrackingService } from '../../../services/cross-sell-tracking.service';
import { HealthInsuranceModalComponent } from './health-insurance-modal/health-insurance-modal.component';

@Component({
  selector: 'ni-health-insurance-cross-sell-policy',
  templateUrl: './health-insurance-cross-sell-policy.component.html',
  styleUrls: ['./health-insurance-cross-sell-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NiButtonRbComponent, TranslateModule],
})
export class HealthInsuranceCrossSellPolicyComponent {
  protected readonly ButtonType = ButtonType;
  private dynamicDialogService = inject(DynamicDialogService);
  private crossSellTrackingService = inject(CrossSellTrackingService);
  private navigationService = inject(NavigationService);

  openHealthInsuranceModal(): void {
    this.crossSellTrackingService.trackClickAddNow(LOB_HI, CrossSellPolicyStatus.ExternalLobAvailable);
    this.dynamicDialogService.close(CoverageCheckupModalComponent);
    this.dynamicDialogService.open(HealthInsuranceModalComponent, {
      dismissableMask: false,
      showHeader: true,
    });
  }

  clickLearnMore(): void {
    this.crossSellTrackingService.trackClickLearnMore(LOB_HI);
    this.navigationService.navigateByLMS(LmsSerials.PublicSiteHealthInsurancePage, true);
  }
}
