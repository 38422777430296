import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[niClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output('niClickOutside') clickOutside = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(targetElement) && !this.isDriverjsElement(targetElement)) {
      this.clickOutside.emit(null);
    }
  }

  isDriverjsElement(targetElement: HTMLElement): boolean {
    return targetElement.className.toString().includes('driver-active') || targetElement.className.toString().includes('driver-popover');
  }
}
