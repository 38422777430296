<div class="payment-method-details-wrapper">
  @if (concatenatedLobNames && displayPaygo) {
    <div class="p-xs-bold lob-title" data-test="lob-title">{{ concatenatedLobNames }}</div>
  }
  <ni-ach-failed-payment-notice *ngIf="achFailureCategory" [achFailureCategory]="achFailureCategory" data-test="ach-failed-payment-notice">
  </ni-ach-failed-payment-notice>
  @if (paymentMethodDetails) {
    <div class="payment-method-details-container p-sm_md">
      <ng-container
        [ngTemplateOutlet]="paymentMethodDetails.type === PaymentMethodType.CreditCard ? creditCardDetails : achDetails"
      ></ng-container>
    </div>
  } @else if (displayPaygo) {
    <div class="payment-method-details-container direction-col">
      <ng-container [ngTemplateOutlet]="payAsYouGoDetails"></ng-container>
    </div>
  } @else {
    <div class="no-payment-method p-sm_md" data-test="no-payment-method">{{ 'BILLING.NO_PAYMENT_METHOD' | translate }}</div>
  }
</div>

<ng-template #creditCardDetails>
  <div class="container">
    <div class="payment-method-details">
      <div class="payment-details-wrapper">
        <img
          class="payment-method-icon"
          src="assets/img/credit-card-icons/{{
            $any(mapCardTypeToIconSvg)[$any(paymentMethodDetails).creditCardDetails.cardType.toLowerCase()] || 'credit-card.svg'
          }}"
          alt="{{ $any(paymentMethodDetails).creditCardDetails.cardType }}"
          data-test="card-icon"
        />
        <span *ngIf="$any(paymentMethodDetails).creditCardDetails.funding != NA" data-test="card-funding">{{
          $any(paymentMethodDetails).creditCardDetails.funding | titlecase
        }}</span>
        <span class="ending-digits-prefix">• • • •</span>
        <div class="payment-method-ending-digits" data-test="card-ending-digits"
          >{{ $any(paymentMethodDetails).creditCardDetails.cardEndingDigits }}
        </div>
        <img src="assets/img/checkmark_circle-green.svg" class="check-icon" data-test="check-icon" alt="Check icon" />
      </div>
      <ng-container [ngTemplateOutlet]="editPaymentLink"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="payAsYouGoDetails"></ng-container>
  </div>
</ng-template>

<ng-template #achDetails>
  <div class="container">
    <div>
      <div class="payment-method-details" [ngClass]="{ 'has-failure': !!achFailureCategory }">
        <div class="payment-details-wrapper">
          <img class="payment-method-icon" src="assets/img/bank-account.svg" alt="Bank account" data-test="bank-icon" />
          <div class="bank-details">
            <div class="bank-name">
              <span data-test="bank-account-name">{{ $any(paymentMethodDetails).achDetails.bankAccount.bankName }}</span>
              <span data-test="bank-account-type"> {{ $any(paymentMethodDetails).achDetails.bankAccount.accountType | titlecase }}</span>
            </div>
            <div class="digits">
              <span class="ending-digits-prefix">• • • •</span>
              <span class="payment-method-ending-digits" data-test="bank-account-ending-digits">{{
                $any(paymentMethodDetails).achDetails.bankAccount.accountEndingDigits
              }}</span>
            </div>
          </div>
          <img *ngIf="achFailureCategory" src="/assets/img/alert-red.svg" class="alert-icon" data-test="alert-icon" alt="Alert icon" />
          <img
            *ngIf="!achFailureCategory"
            src="assets/img/checkmark_circle-green.svg"
            class="check-icon"
            data-test="check-icon"
            alt="Check icon"
          />
        </div>
        <ng-container [ngTemplateOutlet]="editPaymentLink"></ng-container>
      </div>
      @if (achFailureCategory) {
        <div class="actions-btn" [ngClass]="{ 'border-bottom': concatenatedPayGoLobNames.length > 0 && !hidePayAsYouGoDetails }">
          @if (achFailureCategory === AchFailureCategory.BankAccountNotFound) {
            <ni-button-rb
              (click)="onUpdatePaymentMethodClicked()"
              class="payment-failure-cta"
              data-test="update-payment-method-button"
              [buttonType]="ButtonType.Secondary"
              >{{ 'BILLING.UPDATE_PAYMENT_METHOD' | translate }}
            </ni-button-rb>
          } @else {
            <ni-button-rb (click)="openZendeskChat()" class="payment-failure-cta" data-test="chat-now-button"
              >{{ 'BILLING.CHAT_NOW' | translate }}
            </ni-button-rb>
          }
        </div>
      }
    </div>
    <ng-container [ngTemplateOutlet]="payAsYouGoDetails"></ng-container>
  </div>
</ng-template>

<ng-template #editPaymentLink>
  <a
    class="edit-icon"
    [niHelpTip]="HelpTip.UpdatePaymentCard"
    (click)="onUpdatePaymentMethodClicked()"
    data-test="edit-payment-method-icon"
  >
    {{ 'BILLING.EDIT' | translate }}
  </a>
</ng-template>

<ng-template #payAsYouGoDetails>
  @if (displayPaygo) {
    <div>
      @if (paymentMethodDetails) {
        <div class="p-xs-bold lob-title" data-test="lob-names-for-paygo">{{ concatenatedPayGoLobNames }}</div>
      }
      <div class="payment-method-details" [ngClass]="{ 'has-failure': !!achFailureCategory }">
        <div class="payment-details-wrapper">
          <img class="payment-method-icon" src="assets/img/bank-more-setting-dots.svg" alt="Bank account" data-test="bank-icon-ach" />
          <div class="bank-details" data-test="default-bank-ach-details">{{ 'BILLING.BANK_ACH' | translate }}</div>
        </div>
        <div class="bank-link-details-container">
          <a class="link-text" (click)="navigateToApIntego()" data-test="bank-link-text">
            {{ 'BILLING.VIEW_DETAILS_AND_EDIT' | translate }}
            <img class="link-icon" src="assets/img/preview.svg" alt="View details and edit" data-test="link-icon" />
          </a>
        </div>
      </div>
    </div>
  }
</ng-template>
