import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NiButtonRbComponent } from '@next-insurance/ni-material/ni-button-rb';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CustomerReviewModalService } from '../../../core/services/customer-review-modal.service';
import { MarketingModalUtilService } from '../../../core/services/marketing-modal-util.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { coreActions } from '../../../core/store/core.actions';
import { AppState } from '../../../store';

@Component({
  selector: 'ni-customer-review-modal',
  templateUrl: './customer-review-modal.component.html',
  styleUrls: ['./customer-review-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiButtonRbComponent, TranslateModule],
})
export class CustomerReviewModalComponent implements OnInit {
  private store = inject<Store<AppState>>(Store);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private customerReviewModalService = inject(CustomerReviewModalService);
  private navigationService = inject(NavigationService);
  private marketingModalUtilService = inject(MarketingModalUtilService);
  reviewUrl = inject(DynamicDialogConfig).data.reviewUrl;

  ngOnInit(): void {
    this.store.dispatch(coreActions.setShowMarketingModal({ showMarketingModal: false }));
  }

  onLeaveReview(): void {
    this.customerReviewModalService.trackCustomerReviewModalLeaveReview();
    this.marketingModalUtilService.setNeverShowCustomerReviewModal();
    this.dynamicDialogRef.close();
    this.navigationService.navigateTo(this.reviewUrl, true);
  }

  onSkip(): void {
    this.customerReviewModalService.trackCustomerReviewModalSkip();
    this.dynamicDialogRef.close();
  }
}
