import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, State } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
// @ts-ignore
import { safeLocalStorage, safeSessionStorage } from 'safe-storage';

import { EnvConfig } from '../../environments/env.config';
import { environment } from '../../environments/environment';
import { abTestingReducer, AbTestingState } from '../ab-testing/store/ab-testing.reducer';
import { businessReducer, BusinessState } from '../business/store/business.reducer';
import { businessChangeReducer, BusinessChangeState } from '../business-change/store/business-change.reducer';
import { certificatesReducer, CertificatesState } from '../certificates/shared/store/certificates.reducer';
import { claimsReducer, ClaimsState } from '../claims/store/claims.reducer';
import { coreActions } from '../core/store/core.actions';
import { coreInitialState, coreReducer, CoreState } from '../core/store/core.reducer';
import { coveragesReducer, CoveragesState } from '../coverages/store/coverages.reducer';
import { crossSellReducer, CrossSellState } from '../cross-sell/store/cross-sell.reducer';
import { featureFlagsReducer, FeatureFlagsState } from '../feature-flags/store/feature-flags.reducer';
import { paymentReducer, PaymentState } from '../payment/store/payment.reducer';
import { policiesReducer, PoliciesState } from '../policies/store/policies.reducer';

export interface AppState {
  router?: RouterReducerState<any>;
  core?: CoreState;
  business?: BusinessState;
  certificates?: CertificatesState;
  coverages?: CoveragesState;
  claims?: ClaimsState;
  policies?: PoliciesState;
  payment?: PaymentState;
  featureFlags?: FeatureFlagsState;
  crossSell?: CrossSellState;
  businessChange?: BusinessChangeState;
  abTesting?: AbTestingState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  core: coreReducer,
  business: businessReducer,
  certificates: certificatesReducer,
  coverages: coveragesReducer,
  claims: claimsReducer,
  policies: policiesReducer,
  payment: paymentReducer,
  featureFlags: featureFlagsReducer,
  crossSell: crossSellReducer,
  businessChange: businessChangeReducer,
  abTesting: abTestingReducer,
};

export function sessionStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { core: ['user', 'isAfterPurchase'] },
      { certificates: ['customCertificate', 'preQuestionsAnswer'] },
      { businessChange: ['businessChangeData'] },
    ],
    storage: safeSessionStorage,
    rehydrate: true,
    removeOnUndefined: true,
    storageKeySerializer: (key) => `com.ni.portal.${key}`,
  })(reducer);
}

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [],
    storage: safeLocalStorage,
    rehydrate: true,
    removeOnUndefined: true,
    storageKeySerializer: (key) => `com.ni.portal.${key}`,
  })(reducer);
}

export function resetStateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: AppState, action: Action) => {
    if (action.type === coreActions.clearState.type) {
      const clearedState = {
        core: {
          ...coreInitialState,
          isFooterVisible: state.core.isFooterVisible,
          holidayTheme: state.core.holidayTheme,
        },
        featureFlags: state.featureFlags,
        abTesting: state.abTesting,
      };
      return reducer(clearedState, action);
    }

    return reducer(state, action);
  };
}

export function logger<T>(reducer: ActionReducer<State<T>>): ActionReducer<State<T>> {
  // eslint-disable-next-line rxjs/finnish
  return (state$, action) => {
    // eslint-disable-next-line rxjs/finnish
    const result$ = reducer(state$, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state$);
    console.log('action', action);
    console.log('next state', result$);
    console.groupEnd();

    return result$;
  };
}

export const metaReducers: MetaReducer<AppState>[] =
  environment.enableStoreChangesLogs && EnvConfig.isDev()
    ? [logger, sessionStorageReducer, localStorageReducer, resetStateReducer]
    : [sessionStorageReducer, localStorageReducer, resetStateReducer];
