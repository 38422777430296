import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { OnboardingStep } from '../../models/onboarding-step.enum';
import { OnboardingService } from '../../services/onboarding.service';
import { OnboardingTrackingService } from '../../services/onboarding-tracking.service';
import { OnboardingAmazonSellerInformationStepComponent } from './onboarding-amazon-seller-step/onboarding-amazon-seller-information-step.component';
import { OnboardingDownloadAppStepComponent } from './onboarding-download-app-step/onboarding-download-app-step.component';
import { OnboardingFinalStepComponent } from './onboarding-final-step/onboarding-final-step.component';
import { OnboardingStepComponent } from './onboarding-step/onboarding-step.component';
import { OnboardingEmailVerificationStepComponent } from './onboarding-verification-step/onboarding-email-verification-step/onboarding-email-verification-step.component';
import { OnboardingPhoneVerificationStepComponent } from './onboarding-verification-step/onboarding-phone-verification-step/onboarding-phone-verification-step.component';

type OnboardingStepsConfig = {
  [key in OnboardingStep]: Type<OnboardingStepComponent>;
};

@Component({
  selector: 'ni-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingModalComponent implements OnInit, AfterViewInit {
  @ViewChild('stepContainer', { read: ViewContainerRef }) stepContainer: ViewContainerRef;
  remainingSteps: OnboardingStep[];
  currentStepIndex: number;
  onboardingStepsConfig: OnboardingStepsConfig;

  constructor(
    private onboardingService: OnboardingService,
    private onboardingTrackingService: OnboardingTrackingService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<{
      remainingSteps: OnboardingStep[];
    }>,
  ) {}

  ngOnInit(): void {
    this.setStepsConfig();
    this.remainingSteps = this.dynamicDialogConfig.data.remainingSteps;
    this.onboardingTrackingService.trackOnboardingOpened(this.remainingSteps);
    this.currentStepIndex = -1;
  }

  ngAfterViewInit(): void {
    this.moveToNextStep();
  }

  moveToNextStep(): void {
    if (this.currentStepIndex < this.remainingSteps.length - 1) {
      this.currentStepIndex++;
      this.createStepComponent();
    } else {
      this.finishOnBoarding();
    }
  }

  abortOnboarding(): void {
    this.onboardingTrackingService.trackOnboardingAborted();
    this.closeModal();
  }

  private setStepsConfig(): void {
    this.onboardingStepsConfig = {
      [OnboardingStep.EmailVerification]: OnboardingEmailVerificationStepComponent,
      [OnboardingStep.PhoneVerification]: OnboardingPhoneVerificationStepComponent,
      [OnboardingStep.AmazonSellerInformation]: OnboardingAmazonSellerInformationStepComponent,
      [OnboardingStep.MobileApp]: OnboardingDownloadAppStepComponent,
      [OnboardingStep.Final]: OnboardingFinalStepComponent,
    };
  }

  private createStepComponent(): void {
    this.stepContainer.clear();
    const componentToRender: Type<OnboardingStepComponent> = this.onboardingStepsConfig[this.remainingSteps[this.currentStepIndex]];
    const componentRef = this.stepContainer.createComponent(componentToRender);
    this.setStepComponentOutputs(componentRef);
    componentRef.changeDetectorRef.detectChanges();
  }

  private setStepComponentOutputs(componentRef: ComponentRef<any>): void {
    componentRef.instance.moveToNextStep.subscribe(() => this.moveToNextStep());
    componentRef.instance.abortOnboarding.subscribe(() => this.abortOnboarding());
  }

  private closeModal(): void {
    this.dynamicDialogRef.close();
  }

  private finishOnBoarding(): void {
    this.closeModal();
    this.onboardingService.removeOnboardingVisibleQueryParam();
  }
}
