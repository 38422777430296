import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, lastValueFrom, NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { publicPaths } from '../../../../server/config/public-paths.config';
import { BusinessIdService } from '../../business/business-id.service';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { AppState } from '../../store';
import { QueryParams } from '../models/query-params.enum';
import { SessionStorageParams } from '../models/session-storage-params.enum';
import { coreActions } from '../store/core.actions';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { FeatureFlagsService } from './feature-flags.service';
import { HolidayThemeService } from './holiday-theme.service';
import { MixedSessionService } from './mixed-session.service';
import { PortalAuthService } from './portal-auth.service';
import { ReturnUrlService } from './return-url.service';

export enum LoginCallbacksPath {
  PostLogin = '/public/next-login/post-login-callback',
  PostLogout = '/public/next-login/post-logout-callback',
  PostProductRedirectLogin = '/public/next-login/post-product-redirect-login',
  ProductRedirect = '/public/product-redirect-login',
  Logout = '/public/logout',
  PostOktaLogin = '/public/okta-login-callback',
}

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  private featureFlagsService = inject(FeatureFlagsService);
  private translateService = inject(TranslateService);
  private holidayThemeService = inject(HolidayThemeService);
  private portalAuthService = inject(PortalAuthService);
  private window = inject(WINDOW);
  private loginTrackingService = inject(LoginTrackingService);
  private sessionStorage: Storage = inject(SESSION_STORAGE);
  private mixedSessionService: MixedSessionService = inject(MixedSessionService);
  private businessIdService: BusinessIdService = inject(BusinessIdService);
  private httpClient = inject(HttpClient);
  private store = inject(Store<AppState>);
  private returnUrlService = inject(ReturnUrlService);

  async initializeApp(): Promise<boolean> {
    if (this.isLoginPath()) {
      await this.handleLoginPath();
      await this.blockAppLoading();
    }

    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated && !this.isPublicPage()) {
      this.returnUrlService.saveReturnUrl(this.window.location.pathname);
      await this.portalAuthService.navigateToNextLogin();
      await this.blockAppLoading();
    }

    // we need this param to prevent trackingID replacement during login navigations. after a successful login we should remove it
    this.sessionStorage.removeItem(SessionStorageParams.ShouldKeepTrackingId);
    return this.getInitialData();
  }

  private loadTranslateService(): Observable<any> {
    this.translateService.setDefaultLang('en');
    return this.translateService.use('en');
  }

  private blockAppLoading(): Promise<void> {
    return lastValueFrom(NEVER);
  }

  private isPublicPage(): boolean {
    for (const path of publicPaths) {
      const pathRegex = new RegExp(path);
      if (pathRegex.test(this.window.location.pathname)) {
        return true;
      }
    }

    return false;
  }

  private getInitialData(): Promise<boolean> {
    return lastValueFrom(
      forkJoin([
        this.featureFlagsService.loadFeatureFlags(),
        this.loadTranslateService(),
        this.holidayThemeService.loadHolidayTheme(),
      ]).pipe(
        map(([flagsLoaded, translationsLoaded, themeLoaded]) => {
          return flagsLoaded && translationsLoaded && themeLoaded;
        }),
      ),
    );
  }

  private async isAuthenticated(): Promise<boolean | void> {
    try {
      const businessIds = await firstValueFrom(this.httpClient.get<string[]>('api/business/ids'));
      if (businessIds.length === 0) {
        this.handleLoginError(AuthErrorStatuses.BusinessNotFound);
        return await this.blockAppLoading();
      }

      let businessIdQueryParam = new URLSearchParams(this.window.location.search).get(QueryParams.BusinessId);
      if (businessIdQueryParam) {
        this.removeBusinessIdQueryParam();

        if (!businessIds.includes(businessIdQueryParam)) {
          this.handleLoginError(AuthErrorStatuses.WrongBusiness);
          return await this.blockAppLoading();
        }
      }

      let selectedBusinessId = businessIdQueryParam || this.businessIdService.getLastSelectedBusinessId();
      const businessId = businessIds.includes(selectedBusinessId) ? selectedBusinessId : businessIds[0];
      this.businessIdService.setBusinessId(businessId);
      this.store.dispatch(coreActions.setIsUserLoggedIn({ isUserLoggedIn: !!businessId }));
      this.mixedSessionService.postBusinessChangeEvent(businessId);
      return true;
    } catch (error: any) {
      if ([401, 403].includes(error.status)) {
        this.setUserIsLoggedOut();
      } else {
        throw error;
      }
    }

    return false;
  }

  private handleLoginError(loginError: AuthErrorStatuses): void {
    this.setUserIsLoggedOut();
    this.portalAuthService.setLoginErrorInCookie(JSON.stringify({ beStatusErrorCode: loginError }));
    this.portalAuthService.logout();
  }

  private isLoginPath(): boolean {
    return Object.values(LoginCallbacksPath).includes(this.window.location.pathname as LoginCallbacksPath);
  }

  private async handleLoginPath(): Promise<void> {
    switch (this.window.location.pathname) {
      case LoginCallbacksPath.ProductRedirect: {
        await this.portalAuthService.navigateToNextLogin(true);
        break;
      }
      case LoginCallbacksPath.PostProductRedirectLogin: {
        await this.handlePostLogin(true);

        break;
      }
      case LoginCallbacksPath.PostLogin: {
        this.loginTrackingService.trackLandingAfterNextLogin(this.window.location.search);
        await this.handlePostLogin(false);
        break;
      }
      case LoginCallbacksPath.PostOktaLogin: {
        await this.portalAuthService.handlePostOktaLogin();
        this.returnUrlService.handleOktaReturnUrl();
        break;
      }
      case LoginCallbacksPath.PostLogout: {
        await this.portalAuthService.navigateToNextLogin();
        break;
      }
      case LoginCallbacksPath.Logout: {
        const returnUrl = new URLSearchParams(this.window.location.search).get(QueryParams.ReturnUrl) || '/home';
        this.returnUrlService.saveReturnUrl(returnUrl);
        this.portalAuthService.logout();
        break;
      }
      default: {
        logger.error(`login path not found `, null, { path: this.window.location.pathname });
        this.portalAuthService.logout();
        break;
      }
    }
  }

  private async handlePostLogin(isProductRedirectFlow: boolean): Promise<void> {
    let { showBusinessSelectionPage } = await this.portalAuthService.exchangeLoginResponseToTokens(isProductRedirectFlow);
    const hasBusinessIdQueryParam = this.hasBusinessIdQueryParam();
    if (showBusinessSelectionPage && !hasBusinessIdQueryParam) {
      this.window.location.href = '/business-selection';
      await this.blockAppLoading();
    } else {
      this.returnUrlService.redirectToReturnUrl();
    }
  }

  private setUserIsLoggedOut(): void {
    this.businessIdService.removeBusinessId();
    this.store.dispatch(coreActions.setIsUserLoggedIn({ isUserLoggedIn: false }));
  }

  private removeBusinessIdQueryParam(): void {
    const url = new URL(this.window.location.href);
    url.searchParams.delete(QueryParams.BusinessId);
    this.window.history.replaceState({}, '', url.toString());
  }

  private hasBusinessIdQueryParam(): boolean {
    const returnUrl = this.returnUrlService.getReturnUrl();
    return /[?&]business_id=/.test(returnUrl);
  }
}
