<ni-alert-banner *ngIf="isVisible$$()" [bannerType]="BannerType.Info" (closed)="onClose()" data-test="ach-recommendation-banner">
  <div banner-body class="banner-body" data-test="ach-recommendation-banner-body">
    <div class="p-lg-bold">{{ 'ACH_RECOMMENDATION_BANNER.TITLE' | translate }}</div>
    <div class="p-md">{{ 'ACH_RECOMMENDATION_BANNER.DESCRIPTION' | translate }}</div>
  </div>
  <div banner-actions>
    <ni-button-rb [buttonType]="ButtonType.Tertiary" data-test="switch-now-button" (click)="onSwitchNowClicked()">
      {{ 'ACH_RECOMMENDATION_BANNER.ACTION_BTN' | translate }}
    </ni-button-rb>
  </div>
</ni-alert-banner>
