import { inject, Injectable } from '@angular/core';
import { NIError } from '@next-insurance/errors';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { ChatContinuitySessionService } from '@next-insurance/ni-chat';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, lastValueFrom, NEVER } from 'rxjs';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { AuthErrorStatus, AuthErrorStatuses, LoginPreventedLogErrors } from '../../login/models/auth-error-status.enum';
import { Auth0ErrorStatus } from '../../login/models/auth0-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { LoginV2DataService } from '../../login/services/login-v2.data.service';
import { MobileAppEventId } from '../models/mobile-app-send-event.model';
import { SessionStorageParams } from '../models/session-storage-params.enum';
import { ExchangeCodeResponse, TargetApp } from '../models/target-app.model';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { Auth0UtilsService } from './auth0-utils.service';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class PortalAuthService {
  private loginDataServiceV2 = inject(LoginV2DataService);
  private loginTrackingService = inject(LoginTrackingService);
  private auth0UtilsService = inject(Auth0UtilsService);
  private navigationService = inject(NavigationService);
  private mobileAppService = inject(MobileAppService);
  private mobileAppEventsService = inject(MobileAppEventsService);
  private cookieService = inject(CookieService);
  private storage: Storage = inject(SESSION_STORAGE);
  private window = inject(WINDOW);
  private chatContinuitySessionService = inject(ChatContinuitySessionService);

  logout(): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.Logout, closeWebview: true });
      return;
    }

    this.storage.clear();
    this.chatContinuitySessionService.clearSessionIdFromStorage();
    this.loginTrackingService.trackNextLoginLogout();
    this.loginDataServiceV2.logout().subscribe(() => {
      const logoutUrl = this.auth0UtilsService.createLogoutURL();
      this.navigationService.navigateTo(logoutUrl);
    });
  }

  async navigateToNextLogin(isProductRedirectFlow = false): Promise<void> {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.Logout, closeWebview: true });
      return;
    }

    this.storage.setItem(SessionStorageParams.ShouldKeepTrackingId, 'true');
    const loginUrl = await this.auth0UtilsService.createLoginURL(isProductRedirectFlow);
    this.loginTrackingService.trackNavigateToNextLogin(loginUrl);
    this.navigationService.navigateTo(loginUrl);
  }

  // eslint-disable-next-line consistent-return
  async exchangeLoginResponseToTokens(isProductRedirectFlow = false): Promise<ExchangeCodeResponse> {
    try {
      const { errorId, errorDescription, code } = this.extractAuth0Params();
      if (errorId) {
        this.handleAuth0Error(errorId, errorDescription);
        await lastValueFrom(NEVER); // waiting for logout
      }

      const codeVerifier = this.auth0UtilsService.getLoginVerifier();

      const exchangeResponse = await firstValueFrom(this.loginDataServiceV2.exchangeCodeForTokens(code, codeVerifier));

      if ((isProductRedirectFlow || !exchangeResponse.hasEligibleBusiness) && exchangeResponse.targetApp === TargetApp.Trex) {
        this.loginTrackingService.trackAgencyNavigation(isProductRedirectFlow, exchangeResponse.hasEligibleBusiness);
        await this.navigateToTrex();
      }

      if (!exchangeResponse.hasEligibleBusiness) {
        throw new NIError('Exchange code failed: business not found', null, {
          niStatusCode: AuthErrorStatuses.BusinessNotFound,
        });
      }

      this.handleExchangeCodeSuccess();
      return exchangeResponse;
    } catch (error: any) {
      this.auth0UtilsService.deleteLoginVerifier();
      this.handleExchangeCodeError(error);
      await lastValueFrom(NEVER); // waiting for logout
    }
  }

  private async navigateToTrex(): Promise<void> {
    this.window.location.replace(environment.agencyDashboardLink);
    await lastValueFrom(NEVER);
  }

  private extractAuth0Params(): { code: string; errorId: Auth0ErrorStatus; errorDescription: string } {
    const params = new URLSearchParams(this.window.location.search);
    const errorId = params.get('error') as Auth0ErrorStatus;
    const code = params.get('code');
    const errorDescription = params.get('error_description');

    return {
      code,
      errorId,
      errorDescription,
    };
  }

  private handleExchangeCodeSuccess(): void {
    this.loginTrackingService.trackNextLoginCallbackSuccess(null);
    this.auth0UtilsService.deleteLoginVerifier();
  }

  private handleExchangeCodeError(error: any): void {
    const errorStatusCode: AuthErrorStatuses = error.error?.niStatusCode || error.extra?.niStatusCode || AuthErrorStatuses.InternalFailure;
    this.loginTrackingService.trackNextLoginExchangeCodeError(errorStatusCode);
    if (this.shouldLogLoginError(errorStatusCode)) {
      logger.error(`[Next Login] Error while exchanging code for tokens - ${errorStatusCode}`, error, { errorStatusCode });
    }

    this.navigateToLoginWithError(JSON.stringify({ beStatusErrorCode: errorStatusCode }));
  }

  private handleAuth0Error(errorStatus: Auth0ErrorStatus, errorDescription: string): void {
    this.loginTrackingService.trackNextLoginCallbackAuth0Error(errorStatus, errorDescription);
    if (this.shouldLogLoginError(errorDescription)) {
      logger.error(`[Next Login] Received an error from Auth0 - ${errorDescription}`, null, {
        errorStatus,
        errorDescription,
      });
    }

    this.navigateToLoginWithError(JSON.stringify({ code: errorStatus, description: errorDescription }));
  }

  private handleOktaError(errorStatus: Auth0ErrorStatus, errorDescription: string): void {
    this.loginTrackingService.trackOktaCallbackAuth0Error(errorStatus, errorDescription);
    if (this.shouldLogLoginError(errorDescription)) {
      logger.error(`[Okta Login] Received an error from Auth0 - ${errorDescription}`, null, {
        errorStatus,
        errorDescription,
      });
    }

    this.navigateToLoginWithError(JSON.stringify({ beStatusErrorCode: AuthErrorStatuses.OktaLoginFailed }));
  }

  private handleOktaExchangeCodeError(error: any): void {
    const errorStatusCode: AuthErrorStatuses = error.error?.niStatusCode || error.extra?.niStatusCode || AuthErrorStatuses.OktaLoginFailed;
    this.loginTrackingService.trackOktaExchangeCodeFailed(errorStatusCode);
    if (this.shouldLogLoginError(errorStatusCode)) {
      logger.error(`[Okta Login] Error while exchanging code for tokens - ${errorStatusCode}`, error, { errorStatusCode });
    }

    this.navigateToLoginWithError(JSON.stringify({ beStatusErrorCode: AuthErrorStatuses.OktaLoginFailed }));
  }

  private navigateToLoginWithError(cookieContent: string): void {
    this.setLoginErrorInCookie(cookieContent);
    this.logout();
  }

  setLoginErrorInCookie(cookieContent: string): void {
    const oneHourExpiration = addHours(Date.now(), 1);
    this.cookieService.set(environment.loginErrorStatusCookieName, cookieContent, {
      domain: this.getHostDomain(),
      expires: oneHourExpiration,
      secure: true,
      path: '/',
    });
  }

  private getHostDomain(): string {
    return EnvConfig.isDev() ? environment.tld : `.${environment.tld}`;
  }

  private shouldLogLoginError(errorKey: string): boolean {
    return !LoginPreventedLogErrors.includes(errorKey as AuthErrorStatus);
  }

  async handlePostOktaLogin(): Promise<void> {
    try {
      const params = new URLSearchParams(this.window.location.search);
      const code = params.get('code');
      const errorId: Auth0ErrorStatus = params.get('error') as Auth0ErrorStatus;
      const errorDescription = params.get('error_description');

      this.loginTrackingService.trackOktaLoginCallbackStart(params.toString());
      if (errorId || errorDescription) {
        this.handleOktaError(errorId, errorDescription);
        return await lastValueFrom(NEVER); // waiting for logout
      }

      await lastValueFrom(this.loginDataServiceV2.oktaExchangeCode(code));
      this.loginTrackingService.trackOktaExchangeCodeSuccess();
      return await Promise.resolve();
    } catch (error: any) {
      this.loginTrackingService.trackOktaExchangeCodeFailed(error);
      this.handleOktaExchangeCodeError(error);
      return await lastValueFrom(NEVER); // waiting for logout
    }
  }
}
