import { inject, Injectable } from '@angular/core';

import { EnvConfig } from '../../../environments/env.config';
import { BusinessIdService } from '../../business/business-id.service';
import { RefreshModalComponent } from '../components/refresh-modal/refresh-modal.component';
import { refreshModalConfig } from '../components/refresh-modal/refresh-modal.config';
import { DynamicDialogService } from './dynamic-dialog.service';
import { MobileAppService } from './mobile-app.service';

@Injectable({
  providedIn: 'root',
})
export class MixedSessionService {
  private businessChangesBroadcast: BroadcastChannel;
  private dynamicDialogService = inject(DynamicDialogService);
  private mobileAppService = inject(MobileAppService);
  private businessIdService = inject(BusinessIdService);

  constructor() {
    if (!this.mobileAppService.isMobileAppWebview()) {
      this.businessChangesBroadcast = new BroadcastChannel(`business_changed_broadcast_${EnvConfig.getEnv()}`);
      this.listenToBroadcastEvent();
    }
  }

  private listenToBroadcastEvent(): void {
    this.businessChangesBroadcast.addEventListener('message', (event: MessageEvent<string>) => {
      const businessIdFromEvent = event.data;
      const currentBusinessId = this.businessIdService.getBusinessId();

      if (currentBusinessId && currentBusinessId !== businessIdFromEvent) {
        this.openRefreshModal();
      }
    });
  }

  postBusinessChangeEvent(businessId: string): void {
    if (!this.mobileAppService.isMobileAppWebview()) {
      this.businessChangesBroadcast.postMessage(businessId);
    }
  }

  private openRefreshModal(): void {
    this.dynamicDialogService.open(RefreshModalComponent, refreshModalConfig);
  }
}
