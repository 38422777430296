import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Event, NavigationEnd, NavigationStart, ResolveEnd, Router, RouterEvent } from '@angular/router';
import logger from '@next-insurance/logger';
import { NiPerformanceService, PerformanceMeasurementService } from '@next-insurance/ng-core';
import { ChatService, OptionalAbilities } from '@next-insurance/ni-chat';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of, Subscription } from 'rxjs';
import { delay, delayWhen, filter, first, tap, withLatestFrom } from 'rxjs/operators';

import { coiCreationAbTestConfig } from './ab-testing/models/ab-test-config.model';
import { AbTestSection } from './ab-testing/models/ab-test-section.enum';
import { ChatStates } from './chatbot/enums/chat-states.enum';
import { ChatWidgetConfig } from './chatbot/models/chat-button-config-model';
import { ChatbotService } from './chatbot/services/chatbot.service';
import { FeatureFlags } from './core/models/feature-flags.enum';
import { HolidayTheme } from './core/models/holiday-themes.model';
import { QueryParams } from './core/models/query-params.enum';
import { SourceTypes } from './core/models/source-types.enum';
import { AbTestingService } from './core/services/ab-testing.service';
import { AiChatbotService } from './core/services/ai-chatbot.service';
import { CoreTrackingService } from './core/services/core-tracking.service';
import { FeatureFlagsService } from './core/services/feature-flags.service';
import { FullStoryService } from './core/services/fullstory.service';
import { IdleUserService } from './core/services/idle-user.service';
import { LanguageService } from './core/services/language.service';
import { MobileAppService } from './core/services/mobile-app.service';
import { MobileAppEventsService } from './core/services/mobile-app-events.service';
import { PWATrackingService } from './core/services/pwa-tracking.service';
import { ZendeskService } from './core/services/zendesk.service';
import { coreActions } from './core/store/core.actions';
import { coreSelectors } from './core/store/core.selectors';
import { CoverageCheckupService } from './coverage-checkup/services/coverage-checkup.service';
import { OnboardingService } from './onboarding/services/onboarding.service';
import { AppState } from './store';

@Component({
  selector: 'ni-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  isLoading: boolean;
  isFooterVisible$: Observable<boolean>;
  shouldHideHeader: boolean;
  isMobileAppWebview: boolean;
  chatWidgetConfig: ChatWidgetConfig;
  hideLoaderForMobileWebView = false;
  shouldShowMobileAppBanner: boolean;
  isOnboardingSupported: boolean;
  holidayTheme: HolidayTheme;
  private isFirstLanding = true;
  private isLoadingSub: Subscription;
  isUserLoggedIn$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  claimsChatbotState$: Observable<ChatStates>;
  survicateFeedbackFFenabled: boolean;

  protected readonly ChatStates = ChatStates;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    protected zendeskService: ZendeskService,
    private chatbotService: ChatbotService,
    private router: Router,
    private pwaTrackingService: PWATrackingService,
    private store: Store<AppState>,
    private idleUserService: IdleUserService,
    private fullstoryService: FullStoryService,
    private coreTrackingService: CoreTrackingService,
    private performanceMeasurementService: PerformanceMeasurementService,
    private onboardingService: OnboardingService,
    private performanceService: NiPerformanceService,
    private mobileAppService: MobileAppService,
    private mobileAppEventsService: MobileAppEventsService,
    private deviceDetectorService: DeviceDetectorService,
    private abTestingService: AbTestingService,
    private coverageCheckupService: CoverageCheckupService,
    private aiChatbotService: AiChatbotService,
    private chatService: ChatService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    addEventListener('unhandledrejection', (event) => {
      logger.error(
        `Window event listener unhandled rejection (promise: ', ${event.promise}, ', reason: ', ${event.reason}, ').`,
        new Error(),
        {
          event: JSON.stringify(event),
        },
      );
    });
    this.survicateFeedbackFFenabled = this.featureFlagsService.isActive(FeatureFlags.SurvicateFeedback);
    this.hideLoaderForMobileWebView = this.mobileAppEventsService.isHideLoaderEnabled();
    this.performanceMeasurementService.sendDefaultMetrics({
      onFirstPageLoad: coreTrackingService.trackFirstPageLoad.bind(coreTrackingService),
      onNavigation: coreTrackingService.trackNavigation.bind(coreTrackingService),
    });
    this.isLoadingSub = store
      .select(coreSelectors.isLoading)
      .pipe(
        tap((isLoading: boolean) => {
          this.isLoading = isLoading;
        }),
      )
      .subscribe();
    this.isFooterVisible$ = store.select(coreSelectors.isFooterVisible);
    this.languageService.initLanguage();
    this.handleNavigationTabsVisibility();

    router.events.pipe(filter((event: Event | RouterEvent) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      if (event.url.includes('/empty-page') || event.url.includes('/mobile-app-preload')) {
        this.shouldHideHeader = true;
      }
      this.isOnboardingSupported = this.onboardingService.isUrlSupported(event.url);
      if (event.navigationTrigger === 'popstate') {
        this.coreTrackingService.trackHistoryNavigation(event.url);
      }
    });

    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      store.dispatch(coreActions.setLoading({ isLoading: false }));
      this.mobileAppEventsService.sendHideLoaderEvent();
      store.dispatch(coreActions.setFooterVisibility({ isFooterVisible: true }));
      this.idleUserService.handleRouteChange(event.url);
      this.handleFirstLanding();
      this.fullstoryService.fireEvent(event.url, { queryParams: this.activatedRoute.snapshot.queryParams });
      this.performanceService.mark('routeChange');

      if (!this.isOnboardingSupported) {
        this.coverageCheckupService.loadAndOpenCoverageCheckupModalIfEligible();
      }
    });

    this.zendeskService.initZendeskWidget();
    this.isUserLoggedIn$ = this.store.select(coreSelectors.isUserLoggedIn);
    this.isLoading$ = this.store.select(coreSelectors.isLoading);

    this.abTestingService.loadSectionAbTests(AbTestSection.coiCreation).subscribe(() => {
      this.aiChatbotService.init({
        survicateFeedbackFF: this.survicateFeedbackFFenabled,
        optionalAbilities: this.abTestingService.canRunAbTest(coiCreationAbTestConfig) ? [OptionalAbilities.CoiCreation] : [],
      });
    });
  }

  @HostBinding('class')
  get holidayThemeClass(): string {
    return this.holidayTheme?.holidayName || '';
  }

  ngOnInit(): void {
    // waiting for the claim chat animation to complete
    this.claimsChatbotState$ = this.chatbotService.chatbotState$.pipe(
      delayWhen((status) => {
        return status === ChatStates.Closed ? of(null).pipe(delay(700)) : of(null);
      }),
    );
    this.abTestingService.overrideAbTestsByQueryParams();
    this.isMobileAppWebview = this.mobileAppService.isMobileAppWebview();

    if (this.isMobileAppWebview) {
      document.getElementsByTagName('body')[0].classList.add('is-webview');
    }

    this.shouldShowMobileAppBanner = this.deviceDetectorService.isMobile() && !this.isMobileAppWebview;

    this.store
      .select(coreSelectors.getHolidayTheme)
      .pipe(first())
      .subscribe((theme) => {
        this.holidayTheme = theme;
      });
  }

  ngOnDestroy(): void {
    this.isLoadingSub.unsubscribe();
  }

  private handleNavigationTabsVisibility(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd || event instanceof ActivationEnd),
        withLatestFrom(this.store.select(coreSelectors.isUserLoggedIn)),
      )
      .subscribe(([event, isUserLoggedIn]: [Event | RouterEvent, boolean]) => {
        if (event instanceof ResolveEnd && isUserLoggedIn) {
          this.store.dispatch(coreActions.setNavigationTabsVisibility({ isNavigationTabsVisible: true }));
        } else if (event instanceof ActivationEnd && event.snapshot.data.hideNavigationTabs) {
          this.store.dispatch(coreActions.setNavigationTabsVisibility({ isNavigationTabsVisible: false }));
        }
      });
  }

  private handleFirstLanding(): void {
    if (this.isFirstLanding) {
      this.handleQueryParams(this.activatedRoute.snapshot.queryParams);

      this.isFirstLanding = false;
    }
  }

  private handleQueryParams(queryParams: { [key: string]: string }): void {
    this.coreTrackingService.trackSessionData(queryParams);

    const source = queryParams[QueryParams.Source];
    const channel = queryParams[QueryParams.Channel];
    const operation = queryParams[QueryParams.Operation];
    const shouldKeepTrackingId = queryParams[QueryParams.ShouldKeepTrackingId];

    if (source === SourceTypes.PhoneHomeScreen) {
      this.pwaTrackingService.trackClickingOnPhoneIcon();
    }

    if (source === SourceTypes.FunnelPaymentPage && channel === 'app') {
      this.store.dispatch(coreActions.setIsAfterPurchase({ isAfterPurchase: true }));
      this.coreTrackingService.trackAuthenticateAfterPurchase();
    }

    if (operation) {
      this.store.dispatch(coreActions.setIsLandedWithOperation({ isLandedWithOperation: true }));
    }

    if (queryParams[QueryParams.MlToken]) {
      this.coreTrackingService.trackAuthenticateWithMlToken();
    }

    if (shouldKeepTrackingId) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { [QueryParams.ShouldKeepTrackingId]: null },
        queryParamsHandling: 'merge',
      });
    }
  }

  openChat(): void {
    if (this.zendeskService.isChatting()) {
      this.zendeskService.continueChat();
    } else {
      this.chatService.open();
    }
  }
}
