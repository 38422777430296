<div class="container">
  <img class="illustration" src="../../../assets/img/rocket.png" alt="rocket-illustration" />
  <div class="content">
    <div class="h1-bold">{{ 'AFTER_PURCHASE.TITLE' | translate }}</div>
    <div class="h4-regular mt-card">{{ 'AFTER_PURCHASE.DESCRIPTION' | translate }}</div>
    <div class="p-md login-message"
      >{{ 'AFTER_PURCHASE.DID_NOT_GET' | translate }}
      <a data-test="login-button" (click)="loginClicked()">{{ 'AFTER_PURCHASE.LOG_IN' | translate }}</a></div
    >
  </div>
</div>
