import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { Observable } from 'rxjs';

import { CommonConfigAttribute } from '../../models/common-config-attribute.enum';
import { InteractionType } from '../../models/interaction-type.enum';
import { BannersTrackingService } from '../../services/banners-tracking.service';
import { CommonConfigDataService } from '../../services/common-config.data.service';
import { TrackingService } from '../../services/tracking.service';
import { LOCAL_STORAGE } from '../../tokens/local-storage.token';

@Component({
  selector: 'ni-urgent-user-message-banner',
  templateUrl: './urgent-user-message-banner.component.html',
  styleUrls: ['./urgent-user-message-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrgentUserMessageBannerComponent implements OnInit {
  readonly hideUrgentUserMessageStorageKey = 'hideUrgentUserMessageBanner';
  shouldHideBanner: boolean;
  urgentUserMessage$: Observable<string>;
  ButtonType = ButtonType;
  private bannerName = 'urgent-user-message-banner';

  constructor(
    private trackingService: TrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonConfigDataService: CommonConfigDataService,
    private bannersTrackingService: BannersTrackingService,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
  ) {}

  ngOnInit(): void {
    this.shouldHideBanner = !!this.localStorage.getItem(this.hideUrgentUserMessageStorageKey);
    if (!this.shouldHideBanner) {
      this.bannersTrackingService.trackViewBanner(this.bannerName);
      this.urgentUserMessage$ = this.commonConfigDataService.getConfigAttribute(CommonConfigAttribute.UrgentUserMessage);
    }
  }

  onCloseClicked(): void {
    this.bannersTrackingService.trackCloseBannerClicked(this.bannerName);
    this.onClose();
  }

  onOkClicked(): void {
    this.track(InteractionType.Click, 'ok-button-clicked');
    this.onClose();
  }

  private onClose(): void {
    this.shouldHideBanner = true;
    this.changeDetectorRef.markForCheck();
    this.localStorage.setItem(this.hideUrgentUserMessageStorageKey, 'true');
  }

  private track(interactionType: InteractionType, name: string): void {
    this.trackingService.track({
      interactionType,
      placement: 'urgent-user-message-banner',
      name,
    });
  }
}
