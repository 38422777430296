import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Certificate } from '../../../../certificates/shared/models/certificate.model';
import { CertificateType } from '../../../../certificates/shared/models/certificate-type.enum';
import { CertificatesDataService } from '../../../../certificates/shared/services/certificates.data.service';
import { AppUrl } from '../../../../core/models/app-url.enum';
import { DynamicDialogService } from '../../../../core/services/dynamic-dialog.service';
import { ToastService } from '../../../../core/services/toast.service';
import { catchErrorAndLog } from '../../../../shared/utils/catch-error-and-log.utils';
import { OnboardingTrackingService } from '../../../services/onboarding-tracking.service';
import { OnboardingModalComponent } from '../onboarding-modal.component';
import { OnboardingStepComponent } from '../onboarding-step/onboarding-step.component';

@Component({
  selector: 'ni-onboarding-amazon-seller-information-step',
  templateUrl: './onboarding-amazon-seller-information-step.component.html',
  styleUrls: ['./onboarding-amazon-seller-information-step.component.scss', '../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAmazonSellerInformationStepComponent extends OnboardingStepComponent implements OnInit, OnDestroy {
  ButtonType = ButtonType;
  isLoading: boolean;
  private liveCertificateIdSubscription: Subscription = Subscription.EMPTY;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private certificatesDataService: CertificatesDataService,
    private onboardingTrackingService: OnboardingTrackingService,
    private dynamicDialogService: DynamicDialogService,
    private router: Router,
    private toastService: ToastService,
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this.onboardingTrackingService.trackAmazonSellerInformation();
  }

  ngOnDestroy(): void {
    this.liveCertificateIdSubscription.unsubscribe();
  }

  onSkip(): void {
    this.completeStep();
  }

  navigateToLiveCertificate(): void {
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();

    this.liveCertificateIdSubscription = this.getAmazonLiveCertificateId().subscribe((liveCertificateId: string) => {
      if (liveCertificateId) {
        this.onboardingTrackingService.trackAmazonClickViewCertificate();
        this.completeStep();
        this.dynamicDialogService.close(OnboardingModalComponent);
        this.router.navigate([AppUrl.LiveCertificate, liveCertificateId]);
      }
    });
  }

  private getAmazonLiveCertificateId(): Observable<string> | Observable<any> {
    return this.certificatesDataService.getCertificates(CertificateType.CustomCertificate).pipe(
      map((certificates: Certificate[]) => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
        return certificates.find((certificate) => certificate.certificateHolderName === 'Amazon.com Services LLC.').liveCertificateId;
      }),
      catchErrorAndLog(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
        this.toastService.showGeneralErrorToast();
        return of(null);
      }),
    );
  }
}
