<div class="banner-container" [ngClass]="{ 'recommendation-banner': bannerType === BannerType.Info }" *ngIf="!hidden" [@fadeInAndOut]>
  <div class="banner-content">
    <img class="banner-icon" [src]="bannerType === BannerType.Warning ? 'assets/img/warning.svg' : 'assets/img/light-bulbs2.svg'" alt="" />
    <div class="banner-body">
      <ng-content select="[banner-body]"></ng-content>
    </div>
    <div class="banner-actions" [ngClass]="{ 'recommendation-actions': bannerType === BannerType.Info }">
      <ng-content select="[banner-actions]"></ng-content>
    </div>
    <div *ngIf="closable" class="close-button" data-test="alert-banner-close-button" (click)="onClose()">
      <img src="assets/img/x-black-icon.svg" class="close-icon" alt="close" />
    </div>
  </div>
</div>
