import { inject, Injectable } from '@angular/core';
import { addMonths, isAfter } from 'date-fns';
import { first } from 'rxjs/operators';

import { BusinessIdService } from '../../business/business-id.service';
import { FeatureFlags } from '../models/feature-flags.enum';
import { CustomerReviewModalService } from './customer-review-modal.service';
import { FeatureFlagsService } from './feature-flags.service';
import { ReferralProgramService } from './referral-program.service';

@Injectable({
  providedIn: 'root',
})
export class MarketingModalUtilService {
  NEVER_CUSTOMER_REVIEW_MODAL = 'neverShowCustomerReviewModal';
  SHOW_MARKETING_MODAL_DATE = 'showMarketingModalDate';
  NEVER_SHOW_REFERRAL_MODAL = 'neverShowReferralModal';

  private featureFlag = inject(FeatureFlagsService);
  private referralProgramService = inject(ReferralProgramService);
  private customerReviewModalService = inject(CustomerReviewModalService);
  private businessIdService = inject(BusinessIdService);

  private lastMarketingModalDate: string;
  private neverShowReferral: boolean;
  private neverShowCustomerReview: boolean;

  private isReferralEnabled: boolean;
  private isReviewFFEnabled: boolean;

  setNeverShowReferralModal(): void {
    localStorage.setItem(this.getNeverShowReferralKey(), this.NEVER_SHOW_REFERRAL_MODAL);
  }

  setSkipMarketingModalDate(): void {
    const currentDate = new Date();
    localStorage.setItem(this.getShowMarketingModalKey(), currentDate.toString());
  }

  setNeverShowCustomerReviewModal(): void {
    localStorage.setItem(this.getNeverShowCustomerReviewKey(), this.NEVER_CUSTOMER_REVIEW_MODAL);
  }

  private getShowMarketingModalKey(): string {
    return `${this.SHOW_MARKETING_MODAL_DATE}_${this.getBusinessId()}`;
  }

  private getNeverShowReferralKey(): string {
    return `${this.NEVER_SHOW_REFERRAL_MODAL}_${this.getBusinessId()}`;
  }

  private getNeverShowCustomerReviewKey(): string {
    return `${this.NEVER_CUSTOMER_REVIEW_MODAL}_${this.getBusinessId()}`;
  }

  showMarketingModal(): void {
    this.initParams();

    const isMonthPassed = this.isMonthPassed();

    if (!isMonthPassed) return;

    if (this.shouldOpenReviewModal()) {
      this.customerReviewModalService.openCustomerReviewModal();
    } else if (this.shouldOpenReferralModal()) {
      this.referralProgramService.openReferralProgramModal();
    } else if (this.shouldDecideBetweenModals()) {
      this.openRandomModal();
    }

    this.setSkipMarketingModalDate();
  }

  private isMonthPassed(): boolean {
    const currentDate = new Date();
    const lastMarketingModalDate = this.lastMarketingModalDate ? new Date(this.lastMarketingModalDate) : null;

    return !lastMarketingModalDate || isAfter(currentDate, addMonths(lastMarketingModalDate, 1));
  }

  private initParams(): void {
    this.isReferralEnabled = this.getReferralEnabled();
    this.isReviewFFEnabled = this.featureFlag.isActive(FeatureFlags.CustomerReviewModal);

    this.lastMarketingModalDate = localStorage.getItem(this.getShowMarketingModalKey());
    this.neverShowReferral = localStorage.getItem(this.getNeverShowReferralKey()) === this.NEVER_SHOW_REFERRAL_MODAL;
    this.neverShowCustomerReview = localStorage.getItem(this.getNeverShowCustomerReviewKey()) === this.NEVER_CUSTOMER_REVIEW_MODAL;
  }

  private getBusinessId(): string {
    return this.businessIdService.getBusinessId();
  }

  private getReferralEnabled(): boolean {
    let isReferralEnabled: boolean;
    this.referralProgramService
      .isReferralProgramVisible()
      .pipe(first())
      .subscribe((value) => {
        isReferralEnabled = value;
      });
    return isReferralEnabled;
  }

  private shouldOpenReviewModal(): boolean {
    return this.neverShowReferral && !this.neverShowCustomerReview && this.isReviewFFEnabled;
  }

  private shouldOpenReferralModal(): boolean {
    return !this.neverShowReferral && this.neverShowCustomerReview && this.isReferralEnabled;
  }

  private shouldDecideBetweenModals(): boolean {
    return !this.neverShowReferral && !this.neverShowCustomerReview;
  }

  private openRandomModal(): void {
    if (this.isReferralEnabled && !this.isReviewFFEnabled) {
      this.referralProgramService.openReferralProgramModal();
    } else if (!this.isReferralEnabled && this.isReviewFFEnabled) {
      this.customerReviewModalService.openCustomerReviewModal();
    } else if (this.isReferralEnabled && this.isReviewFFEnabled) {
      Math.random() < 0.5
        ? this.referralProgramService.openReferralProgramModal()
        : this.customerReviewModalService.openCustomerReviewModal();
    }
  }
}
