import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AchFailureCategory } from '@next-insurance/core';
import { ButtonType } from '@next-insurance/ni-material/enums';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ACHPaymentMethod } from '../../../payment/models/payment-method-details.model';
import { PaymentService } from '../../../payment/payment.service';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { AppState } from '../../../store';
import { AppUrl } from '../../models/app-url.enum';
import { BannersTrackingService } from '../../services/banners-tracking.service';
import { FailedPaymentBannerTrackingService } from '../../services/failed-payment-banner-tracking.service';

@Component({
  selector: 'ni-ach-failed-payment-banner',
  templateUrl: './ach-failed-payment-banner.component.html',
  styleUrls: ['./ach-failed-payment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchFailedPaymentBannerComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  isVisible: boolean;
  failureCategory: AchFailureCategory;
  protected AchFailureCategory = AchFailureCategory;
  protected ButtonType = ButtonType;
  private bannerName = 'ach-failed-payment-banner';

  constructor(
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private failedPaymentBannerTrackingService: FailedPaymentBannerTrackingService,
    private paymentService: PaymentService,
    private router: Router,
    private bannersTrackingService: BannersTrackingService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(paymentSelectors.isLoading),
      this.store.select(paymentSelectors.getPaymentMethodDetails),
      this.store.select(policiesSelectors.hasOperativeNextPolicy),
      this.paymentService.shouldDisplayFailedPaymentBannersInCurrURL(),
    ])
      .pipe(
        filter(([isLoadingPayments]: [boolean, ACHPaymentMethod, boolean, boolean]) => !isLoadingPayments),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(
        ([, paymentMethodDetails, hasOperativeNextPolicy, shouldBeVisibleInCurrURL]: [boolean, ACHPaymentMethod, boolean, boolean]) => {
          const achFailureCategory = this.paymentService.getAchFailureCategory(paymentMethodDetails);
          if (hasOperativeNextPolicy && shouldBeVisibleInCurrURL && achFailureCategory) {
            this.failureCategory = achFailureCategory;
            this.changeVisibility(true);
            this.bannersTrackingService.trackViewBanner(this.bannerName, { failureCategory: this.failureCategory });
          } else {
            if (this.isVisible) {
              this.bannersTrackingService.trackHideBanner(this.bannerName, { failureCategory: this.failureCategory });
            }
            this.changeVisibility(false);
          }
        },
      );
  }

  goToBillingPage(): void {
    this.failedPaymentBannerTrackingService.trackAchFailedPaymentBannerClickGoToBilling(this.failureCategory);
    this.router.navigateByUrl(AppUrl.Billing);
  }

  private changeVisibility(isVisible: boolean): void {
    this.isVisible = isVisible;
    this.changeDetectorRef.markForCheck();
  }
}
